import React from 'react';
import styles from './CategoryProgressBar.module.css';
import purpleCheck from './assets/purple-check.svg';
type CategoryArray = (string | undefined | null)[];
interface Props {
  pageNumber: number;
  categories: CategoryArray;
}
interface CategorySegment {
  start: number;
  end: number;
  title: string;
}

export function makeCategorySegments(arr: CategoryArray): CategorySegment[] {
  const result = [];
  let start = 0;
  let currentTitle;

  for (let i = 0; i < arr.length; i++) {
    const normalize = (str: string | undefined | null) => str?.trim().replace(/\s+/g, ' ').toUpperCase();
    const current = normalize(arr[i]);

    if (current) {
      if (current !== currentTitle) {
        if (currentTitle) {
          result.push({
            start,
            end: i - 1,
            title: currentTitle,
          });
        }
        start = i;
        currentTitle = current;
      }
    } else {
      let nextDefinedIndex = i + 1;
      while (nextDefinedIndex < arr.length && !normalize(arr[nextDefinedIndex])) {
        nextDefinedIndex++;
      }
      const nextDefined = normalize(arr[nextDefinedIndex]);

      if (nextDefined !== currentTitle) {
        if (currentTitle) {
          result.push({
            start,
            end: i - 1,
            title: currentTitle,
          });
        }
        result.push({
          start: i,
          end: nextDefinedIndex - 1,
          title: '',
        });
        start = nextDefinedIndex;
        currentTitle = nextDefined;
        i = nextDefinedIndex - 1;
      }
    }
  }

  if (currentTitle) {
    result.push({
      start,
      end: arr.length - 1,
      title: currentTitle,
    });
  }
  return result;
}

export function getPctOfSegmentComplete(pageNumber: number, segment: CategorySegment) {
  const pos = pageNumber - segment.start;
  const total = segment.end - segment.start + 1;
  return Math.max(0, Math.min(1, pos / total));
}

export const CategoryProgressBar: React.FC<Props> = ({pageNumber, categories}) => {
  const segments = makeCategorySegments(categories);

  return (
    <div data-testid="category-progress-bar" className={styles.container}>
      {segments.map((s, i) => {
        const pctComplete = pageNumber <= s.end ? getPctOfSegmentComplete(pageNumber, s) : 1;
        const firstSegment = i === 0;
        const lastSegment = i === segments.length - 1;
        const segmentBorderRadius = firstSegment ? styles.firstSegment : lastSegment ? styles.lastSegment : '';
        return (
          <div key={s.title + i} className={styles.segmentContainer}>
            <div className={`${styles.segment} ${segmentBorderRadius}`}>
              <div className={styles.segmentHighlight} style={{width: `${pctComplete * 100}%`}} />
            </div>

            {!lastSegment ? (
              pctComplete === 1 ? (
                <img src={purpleCheck} />
              ) : (
                <div className={styles.segmentPip} />
              )
            ) : null}
          </div>
        );
      })}
    </div>
  );
};
