import React, {useEffect, useState} from 'react';
import spinner from './assets/spinner.svg';
import styles from './LoadingState.module.css';

const loadingPhrases = ['Calculating sleep debt...', 'Determining sleep chronotype...', 'Evaluating sleep quality...'];

export default function LoadingState({fadeOut, duration}: {fadeOut: boolean; duration: number}) {
  const [loadingTextIndex, setLoadingTextIndex] = useState(0);

  useEffect(() => {
    const textCycleTimer = setInterval(() => {
      setLoadingTextIndex(prevIndex => Math.min(prevIndex + 1, loadingPhrases.length - 1));
    }, duration / loadingPhrases.length);

    return () => clearInterval(textCycleTimer);
  }, [duration]);

  return (
    <div className={`${styles.container} ${fadeOut ? styles.fadeOut : ''}`}>
      <div className={styles.loadingContent}>
        <div className={styles.spinner}>
          <img src={spinner} alt="spinner" className={styles.spinnerArc} />
        </div>
        <h1 className={styles.title}>Generating Sleep Snapshot</h1>
        <p className={styles.description}>
          We'll identify factors affecting your sleep and craft a personalized plan to optimize your energy
        </p>
        <p className={styles.calculatingText}>{loadingPhrases[loadingTextIndex]}</p>
      </div>
    </div>
  );
}
