import React, {useCallback, useEffect, useState} from 'react';
import styles from './Toast.module.css';

export interface ToastProps {
  /**
   * The message to display in the toast
   */
  message: string;

  /**
   * Optional icon element to display in the toast
   */
  icon?: React.ReactNode;

  /**
   * Background color of the toast
   * @default '#333333'
   */
  backgroundColor?: string;

  /**
   * Delay before the toast appears (in milliseconds)
   * @default 0
   */
  appearDelay?: number;

  /**
   * Duration the toast is displayed (in milliseconds)
   * @default 3000
   */
  displayDuration?: number;

  /**
   * Callback function called when the toast is closed
   */
  onClose?: () => void;

  /**
   * Whether the toast is visible
   */
  isVisible: boolean;
}

export const Toast: React.FC<ToastProps> = ({
  message,
  icon,
  backgroundColor = '#333333',
  appearDelay = 0,
  displayDuration = 3000,
  onClose,
  isVisible,
}) => {
  const [animationState, setAnimationState] = useState<'hidden' | 'entering' | 'visible' | 'exiting'>('hidden');

  useEffect(() => {
    let appearTimer: NodeJS.Timeout;
    let displayTimer: NodeJS.Timeout;

    if (isVisible && animationState === 'hidden') {
      // Start appear delay timer
      appearTimer = setTimeout(() => {
        setAnimationState('entering');

        // After animation completes, set to visible
        setTimeout(() => {
          setAnimationState('visible');

          // Start display duration timer
          displayTimer = setTimeout(() => {
            setAnimationState('exiting');

            // After exit animation completes, call onClose
            setTimeout(() => {
              if (onClose) onClose();
              setAnimationState('hidden');
            }, 300); // Match the exit animation duration
          }, displayDuration);
        }, 300); // Match the enter animation duration
      }, appearDelay);
    }

    // Clean up timers on unmount or when isVisible changes
    return () => {
      clearTimeout(appearTimer);
      clearTimeout(displayTimer);
    };
  }, [isVisible, appearDelay, displayDuration, onClose, animationState]);

  if (animationState === 'hidden') {
    return null;
  }

  const toastClasses = [
    styles.toast,
    animationState === 'entering' ? styles.entering : '',
    animationState === 'exiting' ? styles.exiting : '',
  ]
    .filter(Boolean)
    .join(' ');

  return (
    <div className={toastClasses} style={{backgroundColor}} role="alert" aria-live="polite">
      {icon ? <div className={styles.icon}>{icon}</div> : null}
      <div className={styles.message}>{message}</div>
    </div>
  );
};

/**
 * A container component for managing multiple toasts
 */
export interface ToastContainerProps {
  children?: React.ReactNode;
  position?: 'top-right' | 'top-left' | 'bottom-right' | 'bottom-left' | 'top-center' | 'bottom-center';
}

export const ToastContainer: React.FC<ToastContainerProps> = props => {
  const {children, position = 'bottom-center'} = props;
  const containerClass = styles.container;
  const positionClass = styles[position];

  return <div className={containerClass + ' ' + positionClass}>{children}</div>;
};

/**
 * Hook for managing toast state
 */
export const useToast = () => {
  const [isVisible, setIsVisible] = useState(false);
  const [toastProps, setToastProps] = useState<Omit<ToastProps, 'isVisible' | 'onClose'>>({
    message: '',
  });

  const showToast = useCallback((props: Omit<ToastProps, 'isVisible' | 'onClose'>) => {
    setToastProps(props);
    setIsVisible(true);
  }, []);

  const hideToast = () => {
    setIsVisible(false);
  };

  return {
    showToast,
    hideToast,
    toastProps: {
      ...toastProps,
      isVisible,
      onClose: hideToast,
    },
  };
};

export default Toast;
