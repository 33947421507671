import {currencySymbolAtStart} from 'utils/locale';
import {PlanItem} from '../PlanSelection/PlanOptions';
import styles from './SinglePlanOptionVariantA.module.css';
import {PriceSuperScript} from '../PriceSuperScript/PriceSuperScript';
interface SinglePlanOptionProps {
  item: PlanItem;
  onClick: () => void;
}

export const SinglePlanOptionVariantA: React.FC<SinglePlanOptionProps> = ({item, onClick}) => {
  const plan = item.plan;
  const atStart = currencySymbolAtStart(plan.introPrice, plan);
  const currencySymbol = plan.label;

  return (
    <div className={styles.container} onClick={onClick}>
      <div className={styles.header}>
        <div>
          <div className={styles.title}>{item.title}</div>
          <div className={styles.priceContainer}>
            <PriceSuperScript
              price={plan.price}
              symbol={currencySymbol}
              symbolAtStart={atStart}
              style={{textDecoration: 'line-through', color: 'rgba(56,35,101,0.7)'}}
            />
            <div style={{display: 'flex', fontWeight: 'bold'}}>
              <PriceSuperScript price={plan.introPrice} symbol={currencySymbol} symbolAtStart={atStart} />
              {'/month'}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
