import {UpsellProductInfo} from 'utils/stripeUpsellProducts';

import styles from './Item.module.css';
import {priceWithCurrency} from 'utils/locale';

type ItemProps = {
  index: number;
  selected: boolean;
  image?: string;
  onClick: () => void;
  product: UpsellProductInfo;
};

export const Item = ({selected, onClick, product, image}: ItemProps) => {
  return (
    <div className={`${styles.container}  ${selected ? styles.selected : ''}`} onClick={onClick}>
      <img src={image} alt={product.title} className={styles.image} />
      <div className={styles.productContainer}>
        <div className={styles.title}>{product.title}</div>
        <div className={styles.description}>{product.description}</div>
        <div className={styles.priceContainer}>
          <span className={styles.price}>{priceWithCurrency(product.price, product)}</span>
          <span className={styles.strikeThroughPrice}>{priceWithCurrency(product.strikeThroughPrice, product)}</span>
          <div className={styles.discount}>SAVE {product.discountPercent}%</div>
        </div>
      </div>
    </div>
  );
};
