import img_female_hero from 'images/female-user.jpg';
import img_graph_multiple from 'images/graph-multiple.png';
import img_male_hero from 'images/male-user.jpg';
import img_wearables_no from 'images/mobile-phone.png';
import img_wearables_yes from 'images/mobile-watch.png';
import img_nfl_nba from 'images/nfl_nba.png';
import img_rise_twinkles from 'images/rise-logo-with-twinkles.png';
import img_sleep_debt_accumulation from 'images/sleep-debt-accumlation-chart.png';
import img_sleep_debt_high from 'images/sleep-debt-high.png';
import img_sleep_debt_low from 'images/sleep-debt-low.png';
import img_sleep_debt_moderate from 'images/sleep-debt-moderate.png';
import img_sleep_debt_reduction_chart from 'images/sleep-debt-reduction-chart.png';
import img_avatar_1 from 'images/avatar-1.png';
import img_avatar_2 from 'images/avatar-2.png';
import img_avatar_3 from 'images/avatar-3.png';
import img_avatar_5 from 'images/avatar-5.png';
import img_trimester_1 from 'images/trimester-1.svg';
import img_trimester_2 from 'images/trimester-2.svg';
import img_trimester_3 from 'images/trimester-3.svg';
import img_hormone_illustration from 'images/hormone_illustration.svg';
import img_hormone_chat_bubbles from 'images/hormone_chat_bubbles.svg';
import RiseInterstitialTemplate from 'screens/RiseSurvey/components/RiseInterstitialTemplate/RiseInterstitialTemplate';
import ProgressChart from 'screens/RiseSurvey/interstitials/ProgressInterstitial/ProgressChart';
import {surveyInstance} from 'screens/RiseSurvey/RiseSurvey';
import {survey_config_base} from 'screens/RiseSurvey/utils/__survey_config_base';
import AnalyzeInterstitialV2 from 'screens/RiseSurvey/interstitials/AnalyzeInterstitialV2/AnalyzeInterstitialV2';
import SleepProfileInterstitial from 'screens/RiseSurvey/interstitials/SleepProfileInterstitial/SleepProfileInterstitial';
import {interstitial} from 'screens/RiseSurvey/utils/interstitial';
import {radioPage, textPage, checkboxPage} from 'screens/RiseSurvey/utils/page';
import {randomize} from 'screens/RiseSurvey/utils/randomize';
import {switchExpression} from 'screens/RiseSurvey/utils/switchExpression';
import {RiseSurveyConfig} from 'types';
import {getSleepDebtZoneFromMidpointOffsets} from 'utils/getSleepDebtZoneFromMidpointOffsets';
import {CallOutInfo} from 'components/CallOutInfo/CallOutInfo';

const getSleepDebtColor = (sleepDebtZone: string) => {
  switch (sleepDebtZone) {
    case 'high':
      return '#E51542';
    case 'low':
      return '#008568';
    case 'moderate':
    default:
      return '#B85C00';
  }
};
const getSleepDebtChartImage = (sleepDebtZone: string) => {
  switch (sleepDebtZone) {
    case 'high':
      return img_sleep_debt_high;
    case 'low':
      return img_sleep_debt_low;
    case 'moderate':
    default:
      return img_sleep_debt_moderate;
  }
};

const calculatedValues = [
  {
    name: 'gendered_noun',
    expression: switchExpression({
      switch: '{gender}',
      cases: {
        Male: 'man',
        Female: 'woman',
        _default: 'person',
      },
    }),
  },
  {
    name: 'gendered_noun_plural',
    expression: switchExpression({
      switch: '{gender}',
      cases: {
        Male: 'men',
        Female: 'women',
        _default: 'people',
      },
    }),
  },
  {
    name: 'gendered_noun_plural_capitalized',
    expression: switchExpression({
      switch: '{gender}',
      cases: {
        Male: 'Men',
        Female: 'Women',
        _default: 'People',
      },
    }),
  },
  {
    name: 'in_your_age_group',
    expression: switchExpression({
      switch: '{age}',
      cases: {
        '18-23': 'in your late teens or early 20s',
        '24-29': 'in your 20s',
        '30s': 'in your 30s',
        '40s': 'in your 40s',
        '50s': 'in your 50s',
        '60+': 'over 60',
        _default: '',
      },
    }),
  },
  {
    name: 'in_their_age_group',
    expression: switchExpression({
      switch: '{age}',
      cases: {
        '18-23': 'in their late teens or early 20s',
        '24-29': 'in their 20s',
        '30s': 'in their 30s',
        '40s': 'in their 40s',
        '50s': 'in their 50s',
        '60+': 'over 60',
        _default: '',
      },
    }),
  },

  {
    name: 'goal_phrase',
    expression: switchExpression({
      switch: '{goal}',
      cases: {
        'Fall asleep faster': 'fall asleep faster',
        'Stay asleep through the night': 'stay asleep all night',
        'Improve sleep quality': 'improve their sleep quality',
        'Wake up feeling refreshed': 'wake refreshed',
        'Feel more energized during the day': 'feel more energized during the day',
        _default: 'improve their sleep',
      },
    }),
  },
  {
    name: 'profile_goal_phrase',
    expression: switchExpression({
      switch: '{goal}',
      cases: {
        'Fall asleep faster': 'Fall asleep faster',
        'Stay asleep through the night': 'Stay asleep through the night',
        'Improve sleep quality': 'Improve sleep quality',
        'Wake up feeling refreshed': 'Wake up feeling refreshed',
        'Feel more energized during the day': 'Feel more energized during the day',
        _default: 'Improve sleep quality',
      },
    }),
  },
  {
    name: 'bedtime_midpoint_midnight_offset',
    expression: switchExpression({
      switch: '{bedtime_routines}',
      cases: {
        'Before 10 PM': '2.5',
        '10-11 PM': '1.5',
        '11 PM-12 AM': '0.5',
        '12-1 AM': '-0.5',
        'After 1 AM': '-1.5',
        Varied: '',
        _default: '',
      },
    }),
  },
  {
    name: 'wake_midpoint_midnight_offset',
    expression: switchExpression({
      switch: '{sleep_patterns}',
      cases: {
        'Before 6 AM': '5.5',
        '6-7 AM': '6.5',
        '7-8 AM': '7.5',
        '8-9 AM': '8.5',
        '9-10 AM': '9.5',
        'After 10 AM': '10',
        _default: '',
      },
    }),
  },
  {
    name: 'ideal_wake_midpoint_midnight_offset',
    expression: switchExpression({
      switch: '{sleep_consistency}',
      cases: {
        'Before 6 AM': '5.5',
        '6-7 AM': '6.5',
        '7-8 AM': '7.5',
        '8-9 AM': '8.5',
        '9-10 AM': '9.5',
        'After 10 AM': '10',
        _default: '',
      },
    }),
  },
];

export const survey_config: RiseSurveyConfig = {
  // Add the images to preload.  These will be preloaded when the survey is loaded.
  // this is optional.
  preloadImages: [
    img_avatar_1,
    img_avatar_2,
    img_avatar_3,
    img_avatar_5,
    img_female_hero,
    img_graph_multiple,
    img_male_hero,
    img_wearables_no,
    img_wearables_yes,
    img_nfl_nba,
    img_rise_twinkles,
    img_sleep_debt_accumulation,
    img_sleep_debt_high,
    img_sleep_debt_low,
    img_sleep_debt_moderate,
    img_sleep_debt_reduction_chart,
  ],
  ...survey_config_base,
  title: 'Women Profile Dec 2024',
  calculatedValues,
  enableAnswerNewStyle: true,

  pages: [
    radioPage({
      category: 'About Me',
      name: 'age',
      title: 'How old are you?',
      description: 'Sleep patterns change with age',
      choices: ['18-23', '24-29', '30s', '40s', '50s', '60+'],
      autoAdvance: true,
      isRequired: true,
    }),

    /**
     * Category: About Me
     */

    radioPage({
      category: 'About Me',
      name: 'gender',
      title: 'What gender do you identify with?',
      description: 'Both biological and societal factors influence sleep patterns',
      choices: ['Female', 'Male', 'Non-Binary', 'Prefer not to say'],
      autoAdvance: true,
      isRequired: true,
    }),
    radioPage({
      category: 'About Me',
      name: 'goal',
      title: 'What is your primary goal?',
      description: 'We’ll personalize your plan to match your goal',
      choices: randomize([
        'Fall asleep faster',
        'Stay asleep through the night',
        'Improve sleep quality',
        'Wake up feeling refreshed',
        'Feel more energized during the day',
      ]),
      autoAdvance: true,
      isRequired: true,
    }),

    interstitial({
      category: 'About Me',
      name: '4_out_of_5_interstitial',
      component: () => (
        <RiseInterstitialTemplate
          headerText={`RISE has helped over 190k people ${surveyInstance.getVariable('goal_phrase')}`}
          heroImage={<img src={img_rise_twinkles} style={{maxHeight: '200px'}} />}
          bodyText={'<div class="text-large leading-loose">Let’s look at your sleep to see how we can help</div>'}
          continueButtonTitle="Next"
        />
      ),
    }),

    /**
     * Category: My Sleep
     */
    radioPage({
      category: 'My Sleep',
      name: 'how_long',
      title: 'How long have you struggled with sleep?',
      description: 'Many {gendered_noun_plural} {in_their_age_group} chronically struggle with sleep',
      choices: ['Weeks', 'Months', 'Years', 'Decades', 'My entire life', "Sleep isn't an issue for me"],
      autoAdvance: true,
      isRequired: true,
    }),
    /** Pregnancy Start */
    radioPage({
      category: 'My Sleep',
      visibleIf: "{age} != '50+' && {gender} == 'Female'",
      name: 'pregnant',
      title: 'Are you currently pregnant?',
      description: 'Many women experience sleep disruptions during pregnancy due to hormonal shifts',
      choices: ['Yes', 'No', 'Prefer not to say'],
      autoAdvance: true,
      isRequired: true,
    }),
    radioPage({
      category: 'My Sleep',
      visibleIf: "{pregnant} == 'Yes'",
      name: 'pregnant_trimester',
      title: 'Which trimester are you in?',
      description:
        'Sleep patterns and quality change significantly during pregnancy. These changes often correspond to trimesters.',
      choices: ['First (1-3 months)', 'Second (3-6 months)', 'Third (6-9 months)'],
      autoAdvance: true,
      isRequired: true,
    }),

    interstitial({
      category: 'My Sleep',
      hideCategory: false,
      hideProgressBar: false,
      visibleIf: "{pregnant_trimester} == 'First (1-3 months)'",
      name: 'first_trimester_interstitial',
      component: () => (
        <RiseInterstitialTemplate
          heroImage={<img src={img_trimester_1} style={{maxHeight: '200px'}} />}
          preHeaderText={<CallOutInfo title="Did you know?" />}
          headerText={
            <div className="left">
              The first trimester of pregnancy is marked by hormone shifts and fatigue due to rising progesterone
            </div>
          }
          continueButtonTitle="Next"
        />
      ),
    }),

    interstitial({
      category: 'My Sleep',
      hideCategory: false,
      hideProgressBar: false,
      visibleIf: "{pregnant_trimester} == 'Second (3-6 months)'",
      name: 'second_trimester_interstitial',
      component: () => (
        <RiseInterstitialTemplate
          heroImage={<img src={img_trimester_2} style={{maxHeight: '200px'}} />}
          preHeaderText={<CallOutInfo title="Did you know?" />}
          headerText={
            <div className="left">
              The second trimester of pregnancy is often called the “honeymoon phase” of sleep as hormone levels
              stabilize
            </div>
          }
          continueButtonTitle="Next"
        />
      ),
    }),

    interstitial({
      category: 'My Sleep',
      hideCategory: false,
      hideProgressBar: false,
      visibleIf: "{pregnant_trimester} == 'Third (6-9 months)'",
      name: 'third_trimester_interstitial',
      component: () => (
        <RiseInterstitialTemplate
          heroImage={<img src={img_trimester_3} style={{maxHeight: '200px'}} />}
          preHeaderText={<CallOutInfo title="Did you know?" />}
          headerText={
            <div className="left">
              The third trimester of pregnancy often has the most physical discomfort and restlessness
            </div>
          }
          continueButtonTitle="Next"
        />
      ),
    }),
    checkboxPage({
      visibleIf: "{age} != '18-29' && {pregnant} != 'Yes' && {gender} == 'Female'",
      category: 'My Sleep',
      name: 'menopause_symptoms',
      title: 'Are any symptoms related to perimenopause or menopause affecting your sleep?',

      description: 'Hormonal changes can cause sleep disruptions for many women {in_their_age_group}',
      isRequired: true,
      choices: [
        'Hot flashes and night sweats',
        'Restless sleep or insomnia',
        'Anxiety or depression',
        'No Symptoms',
        {
          value: 'prefer_not_to_say', //note: we rely on the value being `prefer_not_to_say`  to clear the options in the checkbox question in RiseSurvey.tsx
          text: 'Prefer not to say',
        },
      ],
    }),

    interstitial({
      category: 'My Sleep',
      visibleIf: "{gender} == 'Female'",
      hideCategory: false,
      hideProgressBar: false,
      name: 'hormones_disrupt_sleep',
      component: () => (
        <RiseInterstitialTemplate
          heroImage={<img src={img_hormone_illustration} style={{maxHeight: '200px'}} />}
          preHeaderText={<CallOutInfo title="Did you know?" />}
          headerText={
            <div className="left">
              More than half of all women experience sleep disruptions as their hormones change
            </div>
          }
          continueButtonTitle="Next"
        />
      ),
    }),

    /** Pregnancy End */

    // bedroom_temperature Hot Flash Variant
    radioPage({
      category: 'My Sleep',
      name: 'bedroom_temperature',
      title: 'What is the temperature in your bedroom while sleeping?',
      visibleIf: "{menopause_symptoms} contains 'Hot flashes and night sweats'",
      description: 'Your environment can impact sleep quality and help alleviate hot flashes and night sweats',
      choices: ['Cool and comfortable', 'Warm and cozy', 'Varies throughout the night', 'Too hot or too cold'],
      autoAdvance: true,
      isRequired: true,
    }),
    //bedroom_temperature  No Hot Flash Variant
    radioPage({
      category: 'My Sleep',
      name: 'bedroom_temperature',
      title: 'What is the temperature in your bedroom while sleeping?',
      visibleIf: "!({menopause_symptoms} contains 'Hot flashes and night sweats')",
      description: 'Your environment can impact sleep quality',
      choices: ['Cool and comfortable', 'Warm and cozy', 'Varies throughout the night', 'Too hot or too cold'],
      autoAdvance: true,
      isRequired: true,
    }),

    radioPage({
      category: 'My Sleep',
      name: 'noise_level',
      title: 'What is the noise level in your bedroom while sleeping?',
      description: 'Research indicates noise levels affect how well you sleep through the night',
      choices: ['Silent', 'Some background noise', 'Noisy', 'Varies'],
      autoAdvance: true,
      isRequired: true,
    }),
    radioPage({
      category: 'My Sleep',
      name: 'light_level',
      title: 'How much light is in your bedroom while you sleep? ',
      description: 'Light can disrupt your circadian rhythm and interfere with deep sleep',
      choices: ['Pitch black', 'Dim light', 'Some light', 'Bright light'],
      autoAdvance: true,
      isRequired: true,
    }),

    radioPage({
      category: 'My Sleep',
      name: 'current_quality_v2',
      title: 'How would you rate your current sleep quality?',
      description: 'Sleep quality directly impacts your energy throughout the day',
      choices: ['Stellar', 'Good', 'Okay', 'Poor', 'Awful'],
      autoAdvance: true,
      isRequired: true,
    }),

    radioPage({
      category: 'My Sleep',
      name: 'wake_up',
      title: 'How do you feel when you wake up in the morning?',
      description: 'This helps us understand your sleep quality and circadian rhythm',
      choices: [
        'Refreshed and alert',
        'Well rested but need time to get going',
        'Still tired',
        'Tired and groggy',
        'I need a crane to lift me out of bed',
      ],
      autoAdvance: true,
      isRequired: true,
    }),

    interstitial({
      category: 'My Sleep',
      name: 'sleep_debt_introduction_one_week_interstitial',
      component: () => (
        <RiseInterstitialTemplate
          heroImage={<img src={img_graph_multiple} style={{maxHeight: '233px'}} />}
          preHeaderText={'<div class="left" style="padding-bottom:3px;">You are not alone!</div>'}
          headerText={`<div class="left">Most ${surveyInstance.getVariable('gendered_noun_plural')} ${surveyInstance.getVariable(
            'in_their_age_group',
          )} get <span style="color:#B85C00;">7 hours</span> less sleep every week than their bodies truly need.</div>`}
          continueButtonTitle="Next"
        />
      ),
    }),

    //This will show `Male` gender
    interstitial({
      category: 'My Sleep',
      hideCategory: true,
      hideProgressBar: true,
      visibleIf: "{gender} == 'Male'",
      name: 'testimonial_male_interstitial',
      component: () => (
        <RiseInterstitialTemplate
          heroImage={<img src={img_male_hero} style={{maxHeight: '200px'}} />}
          headerText={`Rise supports ${surveyInstance.getVariable('gendered_noun_plural')} who have struggled with sleep for years`}
          bodyText="<div class='text-large italic'>“In only a few weeks, I found myself more focused, energetic, and productive at work.” <div class='text-medium' style='padding-top:8px;'>-Chase</div></div>"
          continueButtonTitle="Next"
        />
      ),
    }),
    //This will show for any gender not `Male`
    interstitial({
      category: 'My Sleep',
      hideCategory: true,
      hideProgressBar: true,
      visibleIf: "{gender} != 'Male'",
      name: 'testimonial_female_interstitial',
      component: () => (
        <RiseInterstitialTemplate
          heroImage={<img src={img_female_hero} style={{maxHeight: '200px'}} />}
          headerText={`Rise supports ${surveyInstance.getVariable('gendered_noun_plural')} who have struggled with sleep for years`}
          bodyText="<div class='text-large italic'>“I’m kinder to myself when I feel down or less energized. I know how to get my energy back when I need it.” <div class='text-medium' style='padding-top:8px;'>-Morgane</div></div>"
          continueButtonTitle="Next"
        />
      ),
    }),

    radioPage({
      category: 'My Sleep',
      name: 'bedtime_routines',
      title: 'When did you typically go to bed in the last 2 weeks?',
      description: "We'll use this to determine how much sleep your body is getting",
      choices: ['Before 10 PM', '10-11 PM', '11 PM-12 AM', '12-1 AM', 'After 1 AM', 'Varied'],
      autoAdvance: true,
      isRequired: true,
    }),

    radioPage({
      category: 'My Sleep',
      name: 'sleep_patterns',
      title: 'When did you typically wake up in the last 2 weeks?',
      description: "We'll use this to determine how much sleep your body is getting",
      choices: ['Before 6 AM', '6-7 AM', '7-8 AM', '8-9 AM', '9-10 AM', 'After 10 AM'],
      autoAdvance: true,
      isRequired: true,
    }),

    radioPage({
      category: 'My Sleep',
      name: 'sleep_consistency',
      title: 'If you had complete freedom, when would you ideally wake up?',
      description: "We'll use this to understand how your sleep changes based on your schedule.",
      choices: ['Before 6 AM', '6-7 AM', '7-8 AM', '8-9 AM', '9-10 AM', 'After 10 AM'],
      autoAdvance: true,
      isRequired: true,
    }),

    radioPage({
      category: 'My Sleep',
      name: 'consistency_throughout_the_week',
      title: 'Do you have a consistent sleep schedule throughout the week?',
      description: 'Maintaining a consistent sleep schedule improves sleep quality and energy levels',
      choices: ['It varies', 'Mainly consistent, but varies on weekends', 'Consistent across weekdays & weekends'],
      autoAdvance: true,
      isRequired: true,
    }),

    interstitial({
      category: 'My Sleep',
      visibleIf: "{consistency_throughout_the_week} != 'Consistent across weekdays & weekends'",
      name: 'sleep_debt_accumulation_interstitial',
      component: () => (
        <RiseInterstitialTemplate
          preHeaderText={`<div class="left" style="padding-bottom:3px;">This is common</div>`}
          heroImage={<img src={img_sleep_debt_accumulation} style={{maxHeight: '233px'}} />}
          headerText={`<div class="left">Most people lose sleep during the week and try to catch up on weekends, but fall short</div>`}
          bodyText={`<div class="left heading3 color-gray2" style="margin-top:-12px">Sleep Debt accumulates over time</div>`}
          continueButtonTitle="Next"
        />
      ),
    }),
    interstitial({
      category: 'My Sleep',
      name: 'sleep_debt_category_interstitial',
      component: () => (
        <RiseInterstitialTemplate
          heroImage={
            <img
              src={getSleepDebtChartImage(
                getSleepDebtZoneFromMidpointOffsets(
                  surveyInstance.getVariable('bedtime_midpoint_midnight_offset'),
                  surveyInstance.getVariable('wake_midpoint_midnight_offset'),
                ),
              )}
              style={{maxHeight: '226px'}}
            />
          }
          headerText={`<div class="left">Based on what you told us, your estimated Sleep Debt is <span style="color:${getSleepDebtColor(
            getSleepDebtZoneFromMidpointOffsets(
              surveyInstance.getVariable('bedtime_midpoint_midnight_offset'),
              surveyInstance.getVariable('wake_midpoint_midnight_offset'),
            ),
          )}">${getSleepDebtZoneFromMidpointOffsets(
            surveyInstance.getVariable('bedtime_midpoint_midnight_offset'),
            surveyInstance.getVariable('wake_midpoint_midnight_offset'),
          )}</span></div>`}
          bodyText={
            '<div class="left heading3 color-gray3"><div class="bold color-black">What is Sleep Debt?</div>Sleep Debt is the gap between the sleep your body needs and the sleep it gets</div>'
          }
          continueButtonTitle="Next"
          onClick={() => {
            surveyInstance.getQuestionsByNames(['sleep_debt_category_interstitial']).forEach(q => {
              q.value = getSleepDebtZoneFromMidpointOffsets(
                surveyInstance.getVariable('bedtime_midpoint_midnight_offset'),
                surveyInstance.getVariable('wake_midpoint_midnight_offset'),
              );
            });
          }}
        />
      ),
    }),
    interstitial({
      category: 'My Sleep',
      hideCategory: true,
      hideProgressBar: true,
      visibleIf: "{sleep_debt_category_interstitial} != 'Low'",
      name: 'success_stories_interstitial',
      component: () => (
        <RiseInterstitialTemplate
          heroImage={<img src={img_sleep_debt_reduction_chart} style={{maxHeight: '200px'}} />}
          headerText={`Over 190k people have reduced their Sleep Debt with RISE`}
          bodyText={`<div style="font-size:24px;">Now let's explore how your lifestyle affects your sleep and energy`}
          continueButtonTitle="Next"
        />
      ),
    }),

    /**
     * Category: My Lifestyle
     */

    interstitial({
      visibleIf: "{gender} == 'Female'",
      category: 'My Lifestyle',
      hideCategory: false,
      hideProgressBar: false,
      name: 'hormones_disrupt_sleep',
      component: () => (
        <RiseInterstitialTemplate
          heroImage={<img src={img_hormone_chat_bubbles} style={{maxHeight: '283px'}} />}
          bodyText={<div style={{fontSize: '24px'}}>Let's see how your habits affect your sleep</div>}
          continueButtonTitle="Next"
        />
      ),
    }),
    radioPage({
      category: 'My Lifestyle',
      name: 'caffeine_intake',
      title: 'Do you consume coffee or caffeinated drinks?',
      description: 'Properly timing your caffeine intake can help you fall asleep faster',
      choices: ['No', '1 cup', '2 cups', '3 cups', 'More than 3 cups'],
      autoAdvance: true,
      isRequired: true,
    }),
    radioPage({
      category: 'My Lifestyle',
      name: 'hydration',
      title: 'How much water do you typically drink in a day?',
      description: 'Being hydrated promotes energy',
      choices: ['Fewer than 4 glasses', '4-7 glasses', '8 or more glasses', 'It varies'],
      autoAdvance: true,
      isRequired: true,
    }),
    radioPage({
      category: 'My Lifestyle',
      name: 'dinner_timing',
      title: 'When do you typically eat dinner?',
      description:
        'As a {gendered_noun} {in_your_age_group}, meal timing can impact your ability to fall and stay asleep',
      choices: ['Around 6 PM', 'Between 6-8 PM', 'After 8 PM'],
      autoAdvance: true,
      isRequired: true,
    }),
    radioPage({
      category: 'My Lifestyle',
      name: 'have_wearable',
      title: 'Do you use a smart watch or fitness tracker?',
      choices: ['Yes', 'No'],
      autoAdvance: true,
      isRequired: true,
    }),

    interstitial({
      category: 'My Lifestyle',
      hideCategory: true,
      hideProgressBar: true,
      visibleIf: "{have_wearable} == 'Yes'",
      name: 'wearables_interstitial_yes',
      component: () => (
        <RiseInterstitialTemplate
          heroImage={<img src={img_wearables_yes} style={{maxHeight: '250px'}} />}
          headerText={`RISE integrates with your wearable for advanced insights`}
          bodyText={
            '<div class="text-large leading-loose" style="padding-left:22px; padding-right:22px;"><div class="bold text-x-large leading-tight">1.7 Billion</div>nights of sleep tracked</div>'
          }
          continueButtonTitle="Next"
        />
      ),
    }),
    interstitial({
      category: 'My Lifestyle',
      hideCategory: true,
      hideProgressBar: true,
      visibleIf: "{have_wearable} != 'Yes'",
      name: 'wearables_interstitial_no',
      component: () => (
        <RiseInterstitialTemplate
          heroImage={<img src={img_wearables_no} style={{maxHeight: '250px'}} />}
          headerText={`Use your phone to track sleep and get insights—no wearable needed`}
          bodyText={
            '<div class="text-large leading-loose" style="padding-left:22px; padding-right:22px;"><div class="bold text-x-large leading-tight">1.7 Billion</div>nights of sleep tracked</div>'
          }
          continueButtonTitle="Next"
        />
      ),
    }),
    radioPage({
      category: 'My Lifestyle',
      name: 'sports_activity',
      title: 'How often do you exercise or stay active?',
      description: 'Exercise regulates sleep patterns, making it easier to fall asleep and stay asleep',
      choices: ['Regularly', 'Occasionally', 'Rarely', 'It varies'],
      autoAdvance: true,
      isRequired: true,
    }),
    radioPage({
      category: 'My Lifestyle',
      name: 'job_type',
      title: 'What type of job do you have?',
      description: 'Your work hours, stress, and activity impacts your sleep',
      choices: randomize(['Office job', 'Physical labor', 'Remote work', 'Shift work'], ['Other']),
      autoAdvance: true,
      isRequired: true,
    }),
    radioPage({
      category: 'My Lifestyle',
      name: 'screen_time',
      title: 'How long do you spend on screens per day?',
      description: 'Blue light from screens can disrupt your circadian rhythm',
      choices: ['Less than 2 hours', '2-4 hours', '4-6 hours', 'More than 6 hours', 'Varies'],
      autoAdvance: true,
      isRequired: true,
    }),
    radioPage({
      category: 'My Lifestyle',
      name: 'customized_approach',
      title: 'Which best describes you?',
      description: `{gendered_noun_plural_capitalized} {in_their_age_group} need a customized approach to {goal_phrase}. We'll build your plan around your lifestyle and habits.`,
      choices: [
        'I struggle to maintain a healthy sleep schedule',
        'I have some good sleep habits',
        'My sleep routine is strong & my schedule is consistent',
      ],
      autoAdvance: true,
      isRequired: true,
    }),
    interstitial({
      category: 'My Lifestyle',
      name: 'debt_reduction_interstitial',
      component: () => (
        <RiseInterstitialTemplate
          heroImage={<ProgressChart />}
          headerText={`<div class="left">Increase energy with RISE vs. trying on your own</div>`}
          bodyText={`More active RISE users reduce twice as much Sleep Debt as less active users`}
          continueButtonTitle="Next"
        />
      ),
    }),

    /**
     * Category: My Results
     */
    textPage({
      category: 'My Results',
      name: 'person_name',
      title: 'What is your name?',
      description: 'We’ll use this to personalize your experience',
      placeholder: 'Enter name',
      isRequired: true,
    }),
    interstitial({
      category: 'My Results',
      hideCategory: true,
      hideProgressBar: true,
      name: 'analyze_interstitial_v2',
      component: AnalyzeInterstitialV2,
    }),
    interstitial({
      category: 'My Results',
      name: 'sleep_profile_interstitial',
      component: SleepProfileInterstitial,
    }),
    interstitial({
      category: 'My Results',
      hideCategory: true,
      hideProgressBar: true,
      name: 'sports_interstitial_v2',
      component: () => (
        <RiseInterstitialTemplate
          preHeaderText={`<div style=" font-size:12px; font-weight:900; color:#721CFF;">DID YOU KNOW?</div>`}
          heroImage={<img src={img_nfl_nba} style={{maxHeight: '226px'}} />}
          headerText={`<div>Our personalized plan is backed by top <span style="color:#721CFF;">NBA and NFL</span> athletes for peak performance</div>`}
          bodyText={`<div style="margin-top:-24px">After using Rise, <strong>97% of players</strong> reported improvements in their performance and reactions times.</div>`}
          continueButtonTitle="Next"
          onClick={() => {
            surveyInstance.getQuestionsByNames(['sleep_debt_category_interstitial']).forEach(q => {
              q.value = getSleepDebtZoneFromMidpointOffsets(
                surveyInstance.getVariable('bedtime_midpoint_midnight_offset'),
                surveyInstance.getVariable('wake_midpoint_midnight_offset'),
              );
            });
          }}
        />
      ),
    }),
  ],
};
