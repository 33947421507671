import * as Sentry from '@sentry/react';
import {Toast, ToastContainer, useToast} from 'components/Toast/Toast';
import Modal from 'components/Modal/Modal';
import {purchaseProduct} from 'externalAPIs/hypnos';
import checkmark from 'images/check-mark-circle-green.svg';
import {useCallback, useEffect, useMemo, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {ClearButton, PurpleButton} from 'components/CheckoutButtons/CheckoutButtons';
import {useSessionStorage} from 'usehooks-ts';
import Analytics from 'utils/Analytics';
import {getEmailFromSurveyData} from 'utils/getEmailFromSurveyData';
import {growthBook} from 'utils/growthbook';
import {getUserCountry, priceWithCurrency} from 'utils/locale';
import {eBookProduct, UpsellProductInfo} from 'utils/stripeUpsellProducts';
import {ItemSelection} from './components/ItemSelection/ItemSelection';
import style from './UpsellScreen.module.css';
import Environment from 'utils/Environment';
import {getEmailFromUrlParam} from 'utils/getEmailFromUrlParam';

export const canGoToUpsellScreen = () => {
  const featureEnabled = growthBook.getFeatureValue('ups01_ebook_initial_test', false);
  const queryParams = new URLSearchParams(location.search);
  const email = getEmailFromSurveyData() ?? queryParams.get('email');
  const subscriptionId = sessionStorage.getItem('subscription');
  const personId = sessionStorage.getItem('personId');
  return featureEnabled && email && subscriptionId && personId;
};

export const UpsellScreen = () => {
  const queryParams = new URLSearchParams(location.search);
  const email = getEmailFromSurveyData() ?? getEmailFromUrlParam(location.search);
  const {countryCode: locale} = getUserCountry();
  const selected = eBookProduct[locale];
  const [realSubscriptionId] = useSessionStorage('subscription', '');
  const [realPersonId] = useSessionStorage<number | null>('personId', null);
  const qaSubscriptionId = !Environment.isProduction ? queryParams.get('subscriptionId') : undefined;
  const qaPersonId = !Environment.isProduction ? queryParams.get('personId') : undefined;

  const subscriptionId = realSubscriptionId !== '' ? realSubscriptionId : qaSubscriptionId;
  const personId = realPersonId !== null ? realPersonId : qaPersonId;

  const navigate = useNavigate();
  const {showToast, toastProps} = useToast();
  const [isModalOpen, setIsModalOpen] = useState(false);

  const haveRequiredParams = useMemo(() => {
    return subscriptionId && personId && email;
  }, [subscriptionId, personId, email]);

  const goToAccountReady = useCallback(() => {
    if (email) {
      navigate(`/account-ready?email=${encodeURIComponent(email)}`);
    } else {
      navigate(`/account-ready`);
    }
  }, [email, navigate]);

  // Bounce out if we don't have the required params
  useEffect(() => {
    if (!haveRequiredParams) {
      Analytics.track('Skipped Upsell Store Screen, missing required parameters', {
        subscriptionId,
        personId,
        email,
      });
      goToAccountReady();
    }
  }, [haveRequiredParams, goToAccountReady, subscriptionId, personId, email]);

  // Fire Account Created Toast
  useEffect(() => {
    showToast({
      message: 'Account created',
      backgroundColor: '#E5F4F0',
      appearDelay: 500,
      displayDuration: 3000,
      icon: <img src={checkmark} alt="checkmark" />,
    });
  }, [showToast]);

  // Page view analytics
  useEffect(() => {
    if (haveRequiredParams) {
      Analytics.track('Upsell Store Screen Viewed');
    }
  }, [haveRequiredParams]);

  const handlePurchase = async (selectedProduct: UpsellProductInfo) => {
    // Show confirmation modal instead of immediately purchasing
    setIsModalOpen(true);

    // Track modal shown event
    Analytics.track('Upsell Purchase Confirmation Modal Shown', {
      product: selectedProduct,
      price: selectedProduct.price,
      currency: selectedProduct.currency,
    });
  };

  const confirmPurchase = async () => {
    if (!subscriptionId || !personId || !email) {
      throw new Error('Missing required parameters');
    }

    try {
      setIsModalOpen(false);

      // Track confirmation clicked
      Analytics.track('Upsell Purchase Confirmed', {
        product: selected,
        price: selected.price,
        currency: selected.currency,
      });

      await purchaseProduct(subscriptionId, selected, personId, email);

      goToAccountReady();
    } catch (error) {
      console.error('Purchase failed', error);
      Analytics.track('Upsell Purchase Failed', {
        error: error instanceof Error ? error.message : 'Unknown error',
      });
      Sentry.captureException(error);
    }
  };

  return (
    <div className={style.container} data-testid="screen-upsell">
      <div className={style.header}>
        <div className={style.title}>Special offer</div>
        <div className={style.subtitle}>Get your RISE experience started right</div>
      </div>
      <ItemSelection
        selected={selected}
        onSelection={() => null} //Note: there is currently only one selection. This would be to support multiple selections in the future.
      />
      <div className={style.description}>
        Your card will be charged a one-time total of {priceWithCurrency(selected.price, selected)} and the RISE eBook
        will be {email ? `sent to ${email}` : 'emailed to you'}
      </div>
      <div className={style.buttonContainer}>
        <PurpleButton
          title="Purchase eBook"
          onClick={() => {
            Analytics.track('Upsell CTA Clicked', {
              which: 'purchase',
            });
            handlePurchase(selected);
          }}
        />
        <ClearButton
          title="Continue to Download RISE"
          onClick={() => {
            Analytics.track('Upsell CTA Clicked', {
              which: 'skip',
            });
            goToAccountReady();
          }}
        />
      </div>

      {/* Toast container positioned at the bottom center */}
      <ToastContainer position="top-center">
        <Toast {...toastProps} />
      </ToastContainer>

      {/* Confirmation Modal */}
      <Modal
        isOpen={isModalOpen}
        title="Confirm Purchase"
        primaryButtonText="Purchase"
        onPrimaryButtonClick={confirmPurchase}
        secondaryButtonText="Cancel"
        onSecondaryButtonClick={() => {
          setIsModalOpen(false);
          Analytics.track('Upsell Purchase Cancelled');
        }}
      >
        Your card will be charged a one-time total of {priceWithCurrency(selected.price, selected)}.
      </Modal>
    </div>
  );
};
