import {Duration, intervalToDuration} from 'date-fns';
import {useEffect, useState} from 'react';
const formatDuration = (duration: Duration) => {
  const minutes = duration.minutes?.toString().padStart(2, '0') ?? '00';
  const seconds = duration.seconds?.toString().padStart(2, '0') ?? '00';
  return `${minutes}:${seconds}`;
};

export const TimerText = ({timerDuration}: {timerDuration: number}) => {
  const [timedOut, setTimedOut] = useState(false);
  const [timeLeft, setTimeLeft] = useState(timerDuration);

  useEffect(() => {
    const interval = setInterval(() => {
      setTimeLeft(prevTimeLeft => {
        if (prevTimeLeft <= 1000) {
          setTimedOut(true);
          clearInterval(interval);
          return 0;
        }
        return prevTimeLeft - 1000;
      });
    }, 1000);
    return () => clearInterval(interval);
  }, []);
  const duration = intervalToDuration({start: 0, end: timeLeft});
  return <div>{timedOut ? 'We saved your discount!' : `Offer expires in ${formatDuration(duration)}`}</div>;
};
