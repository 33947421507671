import {ClearButton, PurpleButton} from 'components/CheckoutButtons/CheckoutButtons';
import {useMemo, useState, useEffect, useRef} from 'react';
import {getUserCountry, priceWithCurrency} from 'utils/locale';
import {PlanInfo, SupportedCountryCode} from 'utils/stripePlans';
import {SinglePlanOptionVariantA} from '../SinglePlanOption/SinglePlanOptionVariantA';
import {PlanItem, PlanOptions} from './PlanOptions';
import styles from './PlanSelection.module.css';
import Analytics from 'utils/Analytics';

export const PlanSelection = ({
  selectedPlan,
  onPriceSelection,
  onButtonClick,
  weekPlan,
  monthPlan,
  quarterPlan,
}: {
  selectedPlan: PlanInfo;
  onPriceSelection: (plan: PlanInfo) => void;
  onButtonClick: () => void;
  weekPlan: Record<SupportedCountryCode, PlanInfo>;
  monthPlan: Record<SupportedCountryCode, PlanInfo>;
  quarterPlan: Record<SupportedCountryCode, PlanInfo>;
}) => {
  const [showAllPlans, setShowAllPlans] = useState(false);
  const [animatingTransition, setAnimatingTransition] = useState(false);
  const [containerHeight, setContainerHeight] = useState<number | null>(null);
  const fadeContainerRef = useRef<HTMLDivElement>(null);
  const singlePlanRef = useRef<HTMLDivElement>(null);
  const planOptionsRef = useRef<HTMLDivElement>(null);
  const {countryCode: locale} = getUserCountry();
  const weeklyPlan = weekPlan[locale];
  const monthlyPlan = monthPlan[locale];
  const quarterlyPlan = quarterPlan[locale];

  // Set initial height after component mounts
  useEffect(() => {
    if (singlePlanRef.current && fadeContainerRef.current) {
      setContainerHeight(singlePlanRef.current.offsetHeight);
    }
  }, []);

  const items: PlanItem[] = [
    {
      id: 'weekly',
      title: 'Week Trial',
      plan: weeklyPlan,
      description: <div>{priceWithCurrency(weeklyPlan.introPrice ?? 0, weeklyPlan, 0)}</div>,
    },
    {
      subtitle: `SAVE ${monthlyPlan.percentOff}%`,
      id: 'monthly',
      title: 'Monthly',
      plan: monthlyPlan,
      description: (
        <div>
          <span className={styles.strikeoutPrice}>{priceWithCurrency(monthlyPlan.price ?? 0, monthlyPlan, 0)}</span>{' '}
          {priceWithCurrency(monthlyPlan.introPrice ?? 0, monthlyPlan, 0)}
        </div>
      ),
      badgeText: 'Most Popular',
    },
    {
      subtitle: `SAVE ${quarterlyPlan.percentOff}%`,
      id: 'quarterly',
      title: 'Quarterly',
      plan: quarterlyPlan,
      description: (
        <div>
          <span className={styles.strikeoutPrice}>{priceWithCurrency(quarterlyPlan.price ?? 0, quarterlyPlan, 0)}</span>{' '}
          {priceWithCurrency(quarterlyPlan.introPrice ?? 0, quarterlyPlan, 0)}
        </div>
      ),
    },
  ];

  const handleChange = (item: PlanItem) => {
    onPriceSelection(item.plan);
  };

  const handleViewAllPlans = () => {
    Analytics.track('View All Plans Clicked');
    setAnimatingTransition(true);
    setShowAllPlans(true);

    // Measure and set the height of the PlanOptions component after it's rendered
    setTimeout(() => {
      if (planOptionsRef.current && fadeContainerRef.current) {
        setContainerHeight(planOptionsRef.current.offsetHeight);
      }
    }, 50);

    // Reset animation state after animation completes
    setTimeout(() => {
      setAnimatingTransition(false);
    }, 500); // Match this with the animation duration
  };

  const finePrint = useMemo(() => {
    const intro = priceWithCurrency(selectedPlan.introPrice, selectedPlan, 0);
    const price = priceWithCurrency(selectedPlan.price, selectedPlan, 0);
    switch (selectedPlan.id) {
      case quarterlyPlan.id:
        return `First quarter ${intro} with automatic renewal every quarter at ${price}. Cancel anytime.`;
      case monthlyPlan.id:
        return `First month ${intro} with automatic monthly renewal at ${price}. Cancel anytime.`;
      case weeklyPlan.id:
        return `First week ${intro} with automatic monthly renewal at ${price}. Cancel anytime.`;
    }
  }, [monthlyPlan.id, quarterlyPlan.id, selectedPlan, weeklyPlan.id]);
  console.log('containerHeight', containerHeight);
  return (
    <div className={styles.container}>
      <div
        ref={fadeContainerRef}
        className={`${styles.fadeContainer} ${showAllPlans ? styles.expanded : ''}`}
        style={containerHeight ? {height: `${containerHeight}px`, minHeight: `${containerHeight}px`} : undefined}
      >
        {!showAllPlans || animatingTransition ? (
          <div ref={singlePlanRef} className={showAllPlans ? styles.singlePlanFadeOut : ''}>
            <SinglePlanOptionVariantA
              item={{
                ...items[1],
                title: 'Monthly Plan',
              }}
              onClick={() => null}
            />
          </div>
        ) : null}
        {showAllPlans ? (
          <div ref={planOptionsRef} className={animatingTransition ? styles.planOptionsFadeIn : ''}>
            <PlanOptions items={items} defaultSelection={'monthly'} onSelectionChange={handleChange} />
          </div>
        ) : null}
      </div>
      <div className={styles.finePrint} style={{textAlign: !showAllPlans ? 'center' : 'left'}}>
        {finePrint}
      </div>
      {/* For Variant B */}
      {/* <div
        className={styles.finePrint}
        style={{textAlign: !showAllPlans && singleOptionVariant === 'variantA' ? 'center' : 'left'}}
      >
        {finePrint}
      </div> */}

      <div className={styles.cta}>
        <PurpleButton title="Get my plan" onClick={onButtonClick} />
        {!showAllPlans ? (
          <ClearButton
            title="View all plans"
            onClick={handleViewAllPlans}
            style={{fontSize: 14, fontWeight: 'bold', color: 'var(--color-purple1)'}}
          />
        ) : null}
      </div>
    </div>
  );
};
