import RiseSurvey from 'screens/RiseSurvey/RiseSurvey';
import config_control from 'surveys/control_config';
import {useFeature} from '@growthbook/growthbook-react';
import {survey_config as web_ex10_isi} from './web_ex10_isi';

export const controlSurveyConfig = config_control;
export const experimentSurveyConfig = web_ex10_isi;

export const SurveyContainer = () => {
  const inExperiment = useFeature('web_ex10_isi');
  const randomSurveyConfig = inExperiment.value ? experimentSurveyConfig : controlSurveyConfig;
  return <RiseSurvey surveyConfigJson={randomSurveyConfig} />;
};
