import React from 'react';
import {RiseInterstitialProps} from 'screens/RiseSurvey/components/RiseInterstitialTemplate/RiseInterstitialBase';
import RiseSurveyInterstitialControls from 'screens/RiseSurvey/components/RiseSurveyControls/RiseSurveyInterstitialControls';
import {surveyInstance} from 'screens/RiseSurvey/RiseSurvey';
import {getCircadianFromMidpoints} from 'utils/getCircadianFromMidpoints';
import earlyBird from './assets/earlybird.png';
import nightOwl from './assets/nightowl.png';
import scale_early_bird from './assets/scale_early_bird.svg';
import scale_night_owl from './assets/scale_night_owl.svg';
import {Card} from './Card';
import styles from './ISISnapshotInterstitial.module.css';

const variantMap = {
  late: {
    badge: nightOwl,
    chart: scale_night_owl,
    style: styles.nightOwl,
    text: 'night owl',
  },
  early: {
    badge: earlyBird,
    chart: scale_early_bird,
    style: styles.earlyBird,
    text: 'early bird',
  },
};

const ResultContainer: React.FC<{chronotype: 'late' | 'early'}> = ({chronotype = 'late'}) => {
  const {style, chart, text, badge} = variantMap[chronotype] ?? variantMap.late;

  return (
    <Card
      badge={badge}
      bannerText={'RISE works with your natural rhythms to manage sleep and increase energy'}
      style={style}
      body={
        <>
          <img src={chart} className={styles.scaleChart} />
          <div className={styles.resultsText}>
            Based on what you told us, your sleep chronotype is <span className={styles.bold}>{text}</span>
          </div>
        </>
      }
    />
  );
};

const ISISnapshotInterstitial2of3: React.FC<RiseInterstitialProps> = () => {
  const chronotype = getCircadianFromMidpoints(
    surveyInstance.getVariable('bedtime_midpoint_midnight_offset'),
    surveyInstance.getVariable('wake_midpoint_midnight_offset'),
  );
  return (
    <div className={`${styles.container} ${styles.fadeIn}`}>
      <div className={styles.counter}>2/3</div>
      <div className={styles.title}>Sleep Chronotype</div>
      <div className={styles.description}>Your sleep chronotype is when you naturally feel most awake or tired</div>

      <ResultContainer chronotype={chronotype} />

      <RiseSurveyInterstitialControls continueButtonTitle="Next" />
    </div>
  );
};

export default ISISnapshotInterstitial2of3;
