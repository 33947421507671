import React from 'react';
import styles from './PriceToday.module.css';
import giftIcon from './assets/gift.svg';
import {currencySymbolAtStart} from 'utils/locale';
import {PlanInfo} from 'utils/stripePlans';
type Props = {
  title: string;
  plan: PlanInfo;
};

export default function PriceToday({title, plan}: Props) {
  const atStart = currencySymbolAtStart(plan.introPrice, plan);
  return (
    <div className={styles.container}>
      <div className={styles.title}>Price Today</div>
      <div className={styles.priceContainer}>
        <div className={styles.priceIcon}>
          <img src={giftIcon} alt="gift" />
        </div>
        <div className={styles.priceTitle}>{title}</div>
        <div className={styles.price}>
          <div className={styles.currency} style={!atStart ? {order: 2} : {}}>
            {plan.label}
          </div>
          {plan.introPrice.toString()}
        </div>
      </div>
    </div>
  );
}
