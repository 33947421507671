import {AnalyticsBrowser} from '@segment/analytics-next';
import packageJson from '../../package.json';
import Environment from './Environment';
import {growthBook} from 'utils/growthbook';
import {devLog} from 'utils/devLog';
import Identity from './Identity';

const segment = AnalyticsBrowser.load({writeKey: 'wvZrXKtcsn0RWcqV3ixaEZED0xb5K0et'});
console.log(packageJson.version);
const metaPayload = () => {
  return {
    version: packageJson.version,
    environment: Environment.getVar('REACT_APP_ENV') ?? 'test',
    host: window.location.host,
    url: window.location.href,
    urlParams: window.location.search,
    userAgent: window.navigator.userAgent,
  };
};

function track(eventName: string, payload: object = {}) {
  // Because some cases we've seen the ID disappear from localStorage, we'll aggressively ensure it exists.
  const anonId = Identity.ensureAnonymousID();
  const completePayload = {...metaPayload(), web_funnel_anon_id: anonId, ...payload};
  const completeEventName = `[Web] ${eventName}`;
  segment.track(completeEventName, completePayload);

  devLog(`Sent to Amplitude: "${eventName}". Payload: ${JSON.stringify(completePayload, null, '\t')}`);
}

type IdentifyPayload = Record<string, string>;
type AnonymousIdentify = (payload: IdentifyPayload) => void;
type IdentifiedIdentify = (id: string | number, payload?: IdentifyPayload) => void;
const identify: IdentifiedIdentify & AnonymousIdentify = (
  idOrPayload: string | number | IdentifyPayload,
  payload?: IdentifyPayload,
) => {
  const anonId = Identity.ensureAnonymousID();
  const hasPersonId = typeof idOrPayload === 'string' || typeof idOrPayload === 'number';
  //id if known id
  if (hasPersonId) {
    const personId = String(idOrPayload);
    const attr = growthBook.getAttributes();

    growthBook.setAttributes({
      ...attr,
      personId,
      email: payload?.email ?? attr.email,
    });

    segment.identify(personId, {...metaPayload(), ...payload, web_funnel_anon_id: anonId});
    devLog(`Sent to Amplitude Identify: "${personId}". Payload: ${JSON.stringify(payload, null, '\t')}`);
  } else {
    //payload if anonymous id
    segment.identify({...metaPayload(), ...idOrPayload, web_funnel_anon_id: anonId});
    devLog(`Sent to Amplitude Identify: "${anonId}". Payload: ${JSON.stringify(idOrPayload, null, '\t')}`);
  }
};

const waitFor = (ms: number) => new Promise(resolve => setTimeout(resolve, ms));

const analyticsIsReady = () => !Array.isArray(segment);

/**
 * In some cases, we need to ensure that Segment is initialized before sending events
 * (redirecting back to the Checkout Success Page from Stripe Checkout is one example)
 * By default: waits 200 ms and retries 5 times.
 */
const onReady = async (analytics: () => void, retries = 5, waitInMs = 200): Promise<void> => {
  for (let i = 0; i < retries; i++) {
    if (analyticsIsReady()) {
      analytics();
      return;
    }
    await waitFor(waitInMs);
  }
  // Sentry.captureException(new Error("Analytics didn't initialize within window"));
};

const Analytics = {track, identify, onReady, segment};
export default Analytics;
