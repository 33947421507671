import {useEffect, useState} from 'react';
import {ContinueButton} from 'screens/RiseSurvey/components/RiseSurveyControls/ContinueButton';
import icon_circadian from './assets/icon_circadian.svg';
import icon_info from './assets/icon_info.svg';
import icon_sleep from './assets/icon_sleep.svg';
import img_sleep_debt_scale_1 from './assets/scale1.png';
import img_sleep_debt_scale_3 from './assets/scale3.png';
import img_sleep_debt_high from './assets/sleep_debt_high.png';
import img_sleep_debt_low from './assets/sleep_debt_low.png';
import img_sleep_debt_moderate from './assets/sleep_debt_moderate.png';
import CircadianAlignmentModal from './components/CircadianAlignmentModal';
import styles from './SleepProfileInterstitial.module.css';
import {surveyInstance} from 'screens/RiseSurvey/RiseSurvey';
import Analytics from 'utils/Analytics';
import {getCircadianFromMidpoints} from 'utils/getCircadianFromMidpoints';
import {getSleepDebtZoneFromMidpointOffsets} from 'utils/getSleepDebtZoneFromMidpointOffsets';
import {isCircadianAligned} from 'utils/isCircadianAligned';

const sleepDebtChartMap = {
  high: img_sleep_debt_high,
  moderate: img_sleep_debt_moderate,
  low: img_sleep_debt_low,
};
const circadianTypeMap = {
  early: img_sleep_debt_scale_1,
  late: img_sleep_debt_scale_3,
};

function SleepProfileInterstitial() {
  const [showModal, setShowModal] = useState(false);
  const openModal = () => {
    Analytics.track('Profile Circadian Alignment Info Clicked');
    setShowModal(true);
  };
  const sleepDebtZone = getSleepDebtZoneFromMidpointOffsets(
    surveyInstance.getVariable('bedtime_midpoint_midnight_offset'),
    surveyInstance.getVariable('wake_midpoint_midnight_offset'),
  );
  const circadianType = getCircadianFromMidpoints(
    surveyInstance.getVariable('bedtime_midpoint_midnight_offset'),
    surveyInstance.getVariable('wake_midpoint_midnight_offset'),
  );
  const sleepQuality = surveyInstance.getValue('current_quality_v2') || 'Okay';
  const goal = surveyInstance?.getVariable('profile_goal_phrase');

  const alignmentThreshold = 0; //hrs
  const isAligned = isCircadianAligned(
    alignmentThreshold,
    surveyInstance.getVariable('wake_midpoint_midnight_offset'),
    surveyInstance.getVariable('ideal_wake_midpoint_midnight_offset'),
  );

  useEffect(() => {
    Analytics.track('Profile Viewed', {
      sleep_debt_category: sleepDebtZone,
      sleep_quality: sleepQuality,
      alignment: isAligned ? 'Aligned' : 'Not Aligned',
      goal,
    });
  }, [goal, isAligned, sleepDebtZone, sleepQuality]);

  const circadianTypeChart = circadianTypeMap[circadianType];
  const sleepDebtChart = sleepDebtChartMap[sleepDebtZone];
  return (
    <div className={styles.container}>
      <div className={styles.title}>My Sleep Profile</div>
      <div className={styles.sleepDebtGauge}>
        <img src={sleepDebtChart} />
      </div>
      <div className={styles.sleepDebtScale}>
        <img src={circadianTypeChart} />
      </div>
      <div className={styles.pods}>
        <div className={styles.pod}>
          <img src={icon_sleep} />
          <div className={styles.podTitle}>Sleep Quality</div>
          <div className={styles.podValue}>{sleepQuality}</div>
        </div>
        <div className={styles.pod}>
          <img src={icon_info} className={styles.infoIcon} onClick={openModal} />
          <img src={icon_circadian} />
          <div className={styles.podTitle}>Circadian Rhythm</div>
          <div className={styles.podValue}>{isAligned ? 'Aligned' : 'Not Aligned'}</div>
        </div>
      </div>
      <div className={styles.goalPodContainer}>
        <div className={styles.pod}>
          <div className={styles.podTitle}>Goal</div>
          <div className={styles.podValue}>{goal}</div>
        </div>
      </div>
      <div className={styles.floatingButtonContainer}>
        <ContinueButton title="Next" />
      </div>

      <CircadianAlignmentModal showModal={showModal} setShowModal={setShowModal} />
    </div>
  );
}

export default SleepProfileInterstitial;
