import React, {useEffect} from 'react';
import {ClearButton, PurpleButton} from 'components/CheckoutButtons/CheckoutButtons';
import styles from './Modal.module.css';

export interface ModalProps {
  /**
   * Whether the modal is visible
   */
  isOpen: boolean;

  /**
   * The title of the modal
   */
  title: string;

  /**
   * The content of the modal
   */
  children: React.ReactNode;

  /**
   * Text for the primary button
   */
  primaryButtonText: string;

  /**
   * Handler for primary button click
   */
  onPrimaryButtonClick: () => void;

  /**
   * Text for the secondary button
   */
  secondaryButtonText?: string;

  /**
   * Handler for secondary button click
   */
  onSecondaryButtonClick?: () => void;

  /**
   * Handler for when the modal is closed
   */
  onClose?: () => void;

  /**
   * Whether to close the modal when clicking outside
   * @default true
   */
  closeOnOverlayClick?: boolean;
}

export const Modal: React.FC<ModalProps> = ({
  isOpen,
  title,
  children,
  primaryButtonText,
  onPrimaryButtonClick,
  secondaryButtonText,
  onSecondaryButtonClick,
  onClose,
  closeOnOverlayClick = true,
}) => {
  // Prevent scrolling of the body when modal is open
  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = '';
    }

    return () => {
      document.body.style.overflow = '';
    };
  }, [isOpen]);

  if (!isOpen) {
    return null;
  }

  const handleOverlayClick = (e: React.MouseEvent<HTMLDivElement>) => {
    // Only close if clicking directly on the overlay, not its children
    if (e.target === e.currentTarget && closeOnOverlayClick) {
      onClose?.();
    }
  };

  return (
    <div className={styles.modalOverlay} onClick={handleOverlayClick}>
      <div className={styles.modalContainer} onClick={e => e.stopPropagation()}>
        <div className={styles.modalHeader}>
          <h2 className={styles.modalTitle}>{title}</h2>
        </div>

        <div className={styles.modalBody}>{children}</div>

        <div className={styles.modalFooter}>
          <PurpleButton title={primaryButtonText} onClick={onPrimaryButtonClick} />
          {secondaryButtonText && onSecondaryButtonClick ? (
            <ClearButton title={secondaryButtonText} onClick={onSecondaryButtonClick} />
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default Modal;
