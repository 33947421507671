import {getUserCountry} from 'utils/locale';

import {eBookProduct, UpsellProductInfo} from 'utils/stripeUpsellProducts';
import {Item} from './components/Item';
import styles from './ItemSelection.module.css';
import eBookImage from 'images/ebookImage.png';
const {countryCode: locale} = getUserCountry();
const upsellProduct = eBookProduct[locale];

export const ItemSelection = ({
  selected,
  onSelection,
}: {
  selected: UpsellProductInfo | null;
  onSelection: (plan: UpsellProductInfo | null) => void;
}) => {
  return (
    <section className={styles.container}>
      {/* EBook */}
      <Item
        index={1}
        image={eBookImage}
        selected={selected?.id === upsellProduct.id}
        onClick={() => onSelection(upsellProduct)}
        product={upsellProduct}
      />
    </section>
  );
};
