/**
 * Remove Zero Width Spaces
 * Those are used to work around certain problems with allowing a nullish selection
 * in Survey JS
 */
function removeZeroWidthSpaces(input: string) {
  return input?.replace(/\u200B/g, '');
}

export const getSleepDebtZoneFromMidpointOffsets = (
  bedtimeMidpointMidnightOffset: string,
  wakeMidpointMidnightOffset: string,
) => {
  // Remove Zero Width Spaces
  // Those are used to work around certain problems with allowing a nullish selection
  // in Survey JS
  const cleanedBedtimeMidpointMidnightOffset = removeZeroWidthSpaces(bedtimeMidpointMidnightOffset);
  const cleanedWakeMidpointMidnightOffset = removeZeroWidthSpaces(wakeMidpointMidnightOffset);

  // We if Skipped or "Varies" the value is an empty string
  if (cleanedBedtimeMidpointMidnightOffset === '' || cleanedWakeMidpointMidnightOffset === '') {
    return 'moderate';
  }
  const bedtime = parseFloat(cleanedBedtimeMidpointMidnightOffset);
  const wake = parseFloat(cleanedWakeMidpointMidnightOffset);
  /** From kellie
   * Calculate sleep duration from midpoints
   * Assume users need 8.5h
   * IF duration 9+ -> LOW
   * IF duration 7h -> MODERATE
   * IF <7h -> HIGH
   * IF Null => Moderate
   */
  const sleepDuration = bedtime + wake;
  if (sleepDuration >= 9) {
    return 'low';
  } else if (sleepDuration >= 7.5 || sleepDuration === Infinity) {
    return 'moderate';
  }
  return 'high';
};
