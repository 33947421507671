import {Navigate, Route, Routes} from 'react-router-dom';
import AccountReady from 'screens/AccountReady/AccountReady';
import RiseSurvey from 'screens/RiseSurvey/RiseSurvey';
import RunwayerCheckoutScreen from 'screens/CheckoutScreens/RunwayerCheckoutScreen/RunwayerCheckoutScreen';
import {UpsellScreen} from 'screens/UpsellScreen/UpsellScreen';
import {WinbackCheckoutScreen} from 'screens/CheckoutScreens/WinbackCheckout/screens/WinbackCheckout/WinbackCheckoutScreen';
import {SurveyContainer} from 'surveys/SurveyContainer';
import {survey_config as survey_config_women_jan_2025} from 'surveys/women_jan_2025';
import {useFeatureValue} from '@growthbook/growthbook-react';
import EX07_SinglePlanCheckoutScreen from 'screens/CheckoutScreens/EX07_SinglePlanCheckoutScreen/EX07_SinglePlanCheckoutScreen';

const CheckoutScreenContainer = () => {
  const paywallExperimentEnabled = useFeatureValue('web_ex07_single_plan_highlight_variant_a', false);
  if (paywallExperimentEnabled) {
    return <EX07_SinglePlanCheckoutScreen variantName="web_ex07_single_plan_highlight_variant_a" />;
  }
  return <RunwayerCheckoutScreen variantName="runwayer-checkout" />;
};

export const PublicRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<Navigate to="/start" replace />} />
      <Route index path="start" element={<SurveyContainer />} />
      <Route path="w" element={<RiseSurvey surveyConfigJson={survey_config_women_jan_2025} />} />
      <Route path="checkout" element={<CheckoutScreenContainer />} />
      <Route path="welcome-back-discount-checkout" element={<WinbackCheckoutScreen />} />
      <Route path="sign-up-offer" element={<UpsellScreen />} />
      <Route path="account-ready" element={<AccountReady />} />
    </Routes>
  );
};
