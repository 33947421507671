//import Counter from 'components/Counter/Counter';
//import Pill from 'components/Pill/Pill';
import img_avatar_1 from 'images/avatar-1.png';
import img_avatar_2 from 'images/avatar-2.png';
import img_avatar_3 from 'images/avatar-3.png';
import img_avatar_4 from 'images/avatar-4.png';
//import day_city_img from 'images/day_city.png';
import img_female_hero from 'images/female-user.jpg';
import img_graph_multiple from 'images/graph-multiple.png';
import img_male_hero from 'images/male-user.jpg';
import img_wearables_no from 'images/mobile-phone.png';
import img_wearables_yes from 'images/mobile-watch.png';
import img_nfl_nba from 'images/nfl_nba.png';
//import night_houses_img from 'images/night_houses.png';
import progress_chart_v2_img from 'images/progress_chart_v2.png';
import img_rise_twinkles from 'images/rise-logo-with-twinkles.png';
import img_sleep_beliefs_quiz_illustration from 'images/sleep-beliefs-quiz-illustration.svg';
import img_sleep_debt_accumulation from 'images/sleep-debt-accumlation-chart.png';
import img_sleep_debt_high from 'images/sleep-debt-high.png';
import img_sleep_debt_low from 'images/sleep-debt-low.png';
import img_sleep_debt_moderate from 'images/sleep-debt-moderate.png';
import img_sleep_debt_reduction_chart from 'images/sleep-debt-reduction-chart.png';
import img_twinkles from 'images/twinkles.svg';
import {useEffect} from 'react';
import RiseInterstitialTemplate from 'screens/RiseSurvey/components/RiseInterstitialTemplate/RiseInterstitialTemplate';
import AccountCreationInterstitial from 'screens/RiseSurvey/interstitials/AccountCreationInterstitial/AccountCreationInterstitial';
import AnalyzeInterstitialV2 from 'screens/RiseSurvey/interstitials/AnalyzeInterstitialV2/AnalyzeInterstitialV2';
import ISISnapshotInterstitial1of3 from 'screens/RiseSurvey/interstitials/ISISnapshotInterstitial/ISISnapshotInterstitial1of3';
import ISISnapshotInterstitial2of3 from 'screens/RiseSurvey/interstitials/ISISnapshotInterstitial/ISISnapshotInterstitial2of3';
import ISISnapshotInterstitial3of3 from 'screens/RiseSurvey/interstitials/ISISnapshotInterstitial/ISISnapshotInterstitial3of3';
import ISISnapshotInterstitialPlan from 'screens/RiseSurvey/interstitials/ISISnapshotInterstitial/ISISnapshotInterstitialPlan';
import PlanTeaser from 'screens/RiseSurvey/interstitials/PlanTeaser/PlanTeaser';
import {surveyInstance} from 'screens/RiseSurvey/RiseSurvey';
import {survey_config_base} from 'screens/RiseSurvey/utils/__survey_config_base';
import {interstitial} from 'screens/RiseSurvey/utils/interstitial';
import {checkboxPage, radioPage, textPage} from 'screens/RiseSurvey/utils/page';
import {switchExpression} from 'screens/RiseSurvey/utils/switchExpression';
import {RiseSurveyConfig} from 'types';
import Analytics from 'utils/Analytics';

const InfoBox = ({title, body}: {title: string; body: string}) => {
  return (
    <div style={{backgroundColor: 'rgba(142, 88, 255, 0.2)', borderRadius: '16px', padding: '15px', textAlign: 'left'}}>
      <div style={{fontWeight: 'bold', fontSize: '16px', marginBottom: '8px'}}>{title}</div>
      <div style={{fontSize: '16px', paddingTop: '8px'}}>{body}</div>
    </div>
  );
};

const ResultInterstitial = () => {
  const quizQuestions = surveyInstance.getQuestionsByNames([
    'sleep_quiz_8_hours_sleep_question',
    'sleep_quiz_resting_question',
    'sleep_quiz_try_harder_question',
    'sleep_quiz_more_sleep_question',
    'sleep_quiz_weekend_sleep_question',
    'sleep_quiz_older_adults_sleep_question',
    'sleep_quiz_alcohol_sleep_question',
    'sleep_quiz_caffeine_sleep_question',
  ]);
  const answers = quizQuestions.map(question => question.value);
  const score = answers.filter(a => a === 'Disagree').length;

  //if score is 0-3, then Dreamland Dabbler
  //if score is 4-5, then Slumber Scholar
  //if score is 6-8, then Sleep Savant
  let result = {
    title: '',
    body: '',
  };
  if (score >= 6) {
    result = {
      title: 'Sleep Savant',
      body: "You're ready for RISE! Your knowledge of sleep is exceptional. You understand complex sleep concepts and can dispel sleep myths easily.",
    };
  } else if (score >= 4) {
    result = {
      title: 'Slumber Scholar',
      body: "You're ready for RISE! You have a solid grasp of sleep topics and probably share helpful tips with others, though there's room to improve with support.",
    };
  } else {
    result = {
      title: 'Dreamland Dabbler',
      body: "You're ready for RISE! You know a fair amount about sleep, but there's room for deeper understanding. You'll succeed with the right support.",
    };
  }

  const {title, body} = result;

  useEffect(() => {
    Analytics.track('Sleep Myth Quiz Result', {
      score,
      title,
    });
  }, [score, title]);

  return (
    <RiseInterstitialTemplate
      headerText={
        <div
          style={{
            position: 'relative',
            fontStyle: 'normal',
            fontWeight: 700,
            fontSize: '45px',
            lineHeight: '50px',
            textAlign: 'center',
            color: '#382365',
            height: '200px',
            borderRadius: '16px',
            paddingTop: '15px',
            paddingBottom: '0px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <img
            src={img_twinkles}
            style={{position: 'absolute', top: '0', left: '0', right: '0', bottom: '0', margin: 'auto'}}
          />
          {title}
        </div>
      }
      bodyText={
        <div style={{fontSize: '24px', marginTop: '-15px', fontWeight: 'normal', textAlign: 'center'}}>{body}</div>
      }
      continueButtonTitle="Next"
    />
  );
};

const calculatedValues = [
  {
    name: 'gendered_noun',
    expression: switchExpression({
      switch: '{gender}',
      cases: {
        Male: 'man',
        Female: 'woman',
        _default: 'person',
      },
    }),
  },
  {
    name: 'gendered_noun_plural',
    expression: switchExpression({
      switch: '{gender}',
      cases: {
        Male: 'men',
        Female: 'women',
        _default: 'people',
      },
    }),
  },
  {
    name: 'gendered_noun_plural_capitalized',
    expression: switchExpression({
      switch: '{gender}',
      cases: {
        Male: 'Men',
        Female: 'Women',
        _default: 'People',
      },
    }),
  },
  {
    name: 'in_your_age_group',
    expression: switchExpression({
      switch: '{age}',
      cases: {
        '18-23': 'in your late teens or early 20s',
        '24-29': 'in your 20s',
        '30s': 'in your 30s',
        '40s': 'in your 40s',
        '50s': 'in your 50s',
        '60+': 'over 60',
        _default: '',
      },
    }),
  },
  {
    name: 'in_their_age_group',
    expression: switchExpression({
      switch: '{age}',
      cases: {
        '18-23': 'in their late teens or early 20s',
        '24-29': 'in their 20s',
        '30s': 'in their 30s',
        '40s': 'in their 40s',
        '50s': 'in their 50s',
        '60+': 'over 60',
        _default: '',
      },
    }),
  },

  {
    name: 'goal_phrase',
    expression: switchExpression({
      switch: '{goal}',
      cases: {
        'Find my ideal bedtime': 'find their ideal bedtime',
        'Fall asleep faster': 'fall asleep faster',
        'Stay asleep through the night': 'stay asleep all night',
        'Improve sleep quality': 'improve their sleep quality',
        'Wake up earlier': 'wake up earlier',
        'Wake up feeling refreshed': 'wake refreshed',
        'Feel more energized during the day': 'feel more energized during the day',
        _default: 'improve their sleep',
      },
    }),
  },
  {
    name: 'profile_goal_phrase',
    expression: switchExpression({
      switch: '{goal}',
      cases: {
        'Find my ideal bedtime': 'Find your ideal bedtime',
        'Fall asleep faster': 'Fall asleep faster',
        'Stay asleep through the night': 'Stay asleep through the night',
        'Improve sleep quality': 'Improve sleep quality',
        'Wake up earlier': 'Wake up earlier',
        'Wake up feeling refreshed': 'Wake up feeling refreshed',
        'Feel more energized during the day': 'Feel more energized during the day',
        _default: 'Improve sleep quality',
      },
    }),
  },
  {
    name: 'bedtime_midpoint_midnight_offset',
    expression: switchExpression({
      switch: '{bedtime_routines}',
      cases: {
        'Before 10 PM': '2.5',
        '10-11 PM': '1.5',
        '11 PM-12 AM': '0.5',
        '12-1 AM': '-0.5',
        'After 1 AM': '-1.5',
        Varied: '',
        _default: '',
      },
    }),
  },
  {
    name: 'wake_midpoint_midnight_offset',
    expression: switchExpression({
      switch: '{sleep_patterns}',
      cases: {
        'Before 6 AM': '5.5',
        '6-7 AM': '6.5',
        '7-8 AM': '7.5',
        '8-9 AM': '8.5',
        '9-10 AM': '9.5',
        'After 10 AM': '10',
        _default: '',
      },
    }),
  },
  {
    name: 'ideal_wake_midpoint_midnight_offset',
    expression: switchExpression({
      switch: '{sleep_consistency}',
      cases: {
        'Before 6 AM': '5.5',
        '6-7 AM': '6.5',
        '7-8 AM': '7.5',
        '8-9 AM': '8.5',
        '9-10 AM': '9.5',
        'After 10 AM': '10',
        _default: '',
      },
    }),
  },
];
export const survey_config: RiseSurveyConfig = {
  // Add the images to preload.  These will be preloaded when the survey is loaded.
  // this is optional.
  preloadImages: [
    img_avatar_1,
    img_avatar_2,
    img_avatar_3,
    img_avatar_4,
    img_female_hero,
    img_graph_multiple,
    img_male_hero,
    img_wearables_no,
    img_wearables_yes,
    img_nfl_nba,
    img_rise_twinkles,
    img_sleep_debt_accumulation,
    img_sleep_debt_high,
    img_sleep_debt_low,
    img_sleep_debt_moderate,
    img_sleep_debt_reduction_chart,
    img_sleep_beliefs_quiz_illustration,
    img_twinkles,
  ],
  ...survey_config_base,
  title: 'Web Ex10: ISI',
  calculatedValues,
  enableAnswerNewStyle: true,

  pages: [
    /**
     * Category: My Sleep
     */
    radioPage({
      category: 'My Sleep',
      name: 'age',
      title: 'How old are you?',
      description: 'Sleep patterns change with age',
      choices: ['18-23', '24-29', '30s', '40s', '50s', '60+'],
      autoAdvance: true,
      isRequired: true,
      identifyPropertyName: 'age',
    }),

    radioPage({
      category: 'My Sleep',
      name: 'gender',
      title: 'What gender do you identify with?',
      description: 'Both biological and societal factors influence sleep patterns',
      choices: ['Female', 'Male', 'Non-Binary', 'Prefer not to say'],
      autoAdvance: true,
      isRequired: true,
      identifyPropertyName: 'gender',
    }),
    checkboxPage({
      category: 'My Sleep',
      name: 'sleep_challenges',
      title: 'Do you struggle with any of these sleep challenges?',
      description: 'Select all that apply',
      choices: [
        'Falling asleep',
        'Staying asleep through the night',
        'Waking too early',
        'Difficulty waking up',
        {
          value: 'none_of_the_above',
          text: 'None of these',
        },
      ],
      isRequired: true,
    }),
    radioPage({
      category: 'My Sleep',
      name: 'current_quality_v2',
      title: 'How would you rate your current sleep quality?',
      description: 'Sleep quality directly impacts your energy throughout the day',
      choices: ['Stellar', 'Good', 'Okay', 'Poor', 'Awful'],
      autoAdvance: true,
      isRequired: true,
      identifyPropertyName: 'current_sleep_quality',
    }),
    //This will show `Male` gender
    interstitial({
      category: 'My Sleep',
      hideCategory: true,
      hideProgressBar: true,
      visibleIf: "{gender} == 'Male'",
      name: 'testimonial_male_interstitial',
      component: () => (
        <RiseInterstitialTemplate
          heroImage={<img src={img_male_hero} style={{maxHeight: '200px'}} />}
          headerText={`Rise supports ${surveyInstance.getVariable('gendered_noun_plural')} who have struggled with sleep for years`}
          bodyText="<div class='text-large italic'>“In only a few weeks, I found myself more focused, energetic, and productive at work.” <div class='text-medium' style='padding-top:8px;'>-Chase</div></div>"
          continueButtonTitle="Next"
        />
      ),
    }),
    //This will show for any gender not `Male`
    interstitial({
      category: 'My Sleep',
      hideCategory: true,
      hideProgressBar: true,
      visibleIf: "{gender} != 'Male'",
      name: 'testimonial_female_interstitial',
      component: () => (
        <RiseInterstitialTemplate
          heroImage={<img src={img_female_hero} style={{maxHeight: '200px'}} />}
          headerText={`Rise supports ${surveyInstance.getVariable('gendered_noun_plural')} who have struggled with sleep for years`}
          bodyText="<div class='text-large italic'>“I’m kinder to myself when I feel down or less energized. I know how to get my energy back when I need it.” <div class='text-medium' style='padding-top:8px;'>-Morgane</div></div>"
          continueButtonTitle="Next"
        />
      ),
    }),
    radioPage({
      category: 'My Sleep',
      name: 'goal',
      title: 'What is your primary goal?',
      description: "We'll personalize your plan to match your goal",
      choices: [
        'Find my ideal bedtime',
        'Fall asleep faster',
        'Stay asleep through the night',
        'Improve sleep quality',
        'Wake up earlier',
        'Wake up feeling refreshed',
        'Feel more energized during the day',
      ],
      autoAdvance: true,
      isRequired: true,
      identifyPropertyName: 'primary_goal',
    }),

    radioPage({
      category: 'My Sleep',
      name: 'wake_up',
      title: 'How do you feel when you wake up in the morning?',
      description: 'This helps us understand your sleep quality and circadian rhythm',
      choices: [
        'Refreshed and alert',
        'Well rested but need time to get going',
        'Still tired',
        'Tired and groggy',
        'I need a crane to lift me out of bed',
      ],
      autoAdvance: true,
      isRequired: true,
    }),
    radioPage({
      category: 'My Sleep',
      name: 'daytime_energy',
      title: 'How do you feel during the day?',
      description: 'Sleep affects your energy levels throughout the day',
      choices: [
        'Energized all day',
        'Mainly energized',
        'Fluctuates throughout the day',
        'Mainly tired',
        'Exhausted all day',
      ],
      autoAdvance: true,
      isRequired: true,
    }),
    interstitial({
      category: 'My Sleep',
      name: 'sleep_debt_introduction_one_week_interstitial',
      component: () => (
        <RiseInterstitialTemplate
          heroImage={<img src={img_graph_multiple} style={{maxHeight: '233px'}} />}
          preHeaderText={'<div class="left" style="padding-bottom:3px;">You are not alone!</div>'}
          headerText={`<div class="left">Most ${surveyInstance.getVariable('gendered_noun_plural')} ${surveyInstance.getVariable(
            'in_their_age_group',
          )} get <span style="color:#B85C00;">7 hours</span> less sleep every week than their bodies truly need.</div>`}
          continueButtonTitle="Next"
        />
      ),
    }),
    interstitial({
      category: 'My Sleep',
      hideCategory: true,
      hideProgressBar: true,
      //visibleIf: "{sleep_debt_category_interstitial} != 'Low'",
      name: 'success_stories_interstitial',
      component: () => (
        <RiseInterstitialTemplate
          heroImage={<img src={img_sleep_debt_reduction_chart} style={{maxHeight: '200px'}} />}
          headerText={`Over 250k people have reduced their Sleep Debt with RISE`}
          bodyText={`<div style="font-size:24px;">Now let's explore how your lifestyle affects your sleep and energy`}
          continueButtonTitle="Next"
        />
      ),
    }),

    /**
     * Category: Sleep Patterns
     */
    // Start ISI
    radioPage({
      category: 'Sleep Patterns',
      name: 'isi_falling_asleep_difficulty',
      title: 'How difficult is it for you to FALL asleep?',
      description: 'Reflect on the past two weeks',
      choices: [
        {value: '0', text: 'Not at all difficult'},
        {value: '1', text: 'A little difficult'},
        {value: '2', text: 'Somewhat difficult'},
        {value: '3', text: 'Difficult'},
        {value: '4', text: 'Very difficult'},
      ],
      autoAdvance: true,
      isRequired: true,
    }),

    radioPage({
      category: 'Sleep Patterns',
      name: 'isi_staying_asleep_difficulty',
      title: 'How difficult is it for you to STAY asleep?',
      description: 'Reflect on the past two weeks',
      choices: [
        {value: '0', text: 'Not at all difficult'},
        {value: '1', text: 'A little difficult'},
        {value: '2', text: 'Somewhat difficult'},
        {value: '3', text: 'Difficult'},
        {value: '4', text: 'Very difficult'},
      ],
      autoAdvance: true,
      isRequired: true,
    }),

    radioPage({
      category: 'Sleep Patterns',
      name: 'isi_early_waking',
      title: 'Rate your struggle with waking up too early',
      description: 'Reflect on the past two weeks',
      choices: [
        {value: '0', text: 'Not an issue'},
        {value: '1', text: 'Mild'},
        {value: '2', text: 'Moderate'},
        {value: '3', text: 'Severe'},
        {value: '4', text: 'Very severe'},
      ],
      autoAdvance: true,
      isRequired: true,
    }),

    radioPage({
      category: 'Sleep Patterns',
      name: 'isi_sleep_satisfaction',
      title: 'How satisfied are you with your sleep pattern?',
      description: 'Reflect on the past two weeks',
      choices: [
        {value: '0', text: 'Very satisfied'},
        {value: '1', text: 'Satisfied'},
        {value: '2', text: 'Moderately satisfied'},
        {value: '3', text: 'Dissatisfied'},
        {value: '4', text: 'Very dissatisfied'},
      ],
      autoAdvance: true,
      isRequired: true,
    }),

    radioPage({
      category: 'Sleep Patterns',
      name: 'isi_daily_functioning',
      title: 'Does your sleep interfere with your daily functioning?',
      description: 'Consider your daytime fatigue, ability to focus, and mood',
      choices: [
        {value: '0', text: 'Not at all'},
        {value: '1', text: 'Barely'},
        {value: '2', text: 'Somewhat'},
        {value: '3', text: 'Much'},
        {value: '4', text: 'Very much interfering'},
      ],
      autoAdvance: true,
      isRequired: true,
    }),

    radioPage({
      category: 'Sleep Patterns',
      name: 'isi_sleep_problems_noticeable',
      title: 'How noticeable do you think your sleep problems are to others?',
      description: 'This helps us understand your sleep quality',
      choices: [
        {value: '0', text: 'Not at all noticeable'},
        {value: '1', text: 'A little'},
        {value: '2', text: 'Somewhat'},
        {value: '3', text: 'Much'},
        {value: '4', text: 'Very much noticeable'},
      ],
      autoAdvance: true,
      isRequired: true,
    }),

    radioPage({
      category: 'Sleep Patterns',
      name: 'isi_sleep_worry',
      title: 'How worried/distressed are you about your sleep?',
      description: 'This helps us understand your sleep quality',
      choices: [
        {value: '0', text: 'Not at all worried'},
        {value: '1', text: 'A little'},
        {value: '2', text: 'Somewhat'},
        {value: '3', text: 'Much'},
        {value: '4', text: 'Very much worried'},
      ],
      autoAdvance: true,
      isRequired: true,
    }),

    // END ISI
    radioPage({
      category: 'Sleep Patterns',
      name: 'have_wearable',
      title: 'Do you use a smart watch or fitness tracker?',
      choices: ['Yes', 'No'],
      autoAdvance: true,
      isRequired: true,
    }),

    interstitial({
      category: 'Sleep Patterns',
      hideCategory: true,
      hideProgressBar: true,
      visibleIf: "{have_wearable} == 'Yes'",
      name: 'wearables_interstitial_yes',
      component: () => (
        <RiseInterstitialTemplate
          heroImage={<img src={img_wearables_yes} style={{maxHeight: '250px'}} />}
          headerText={`RISE integrates with your wearable for advanced insights`}
          bodyText={
            '<div class="text-large leading-loose" style="padding-left:22px; padding-right:22px;"><div class="bold text-x-large leading-tight">2.5 Billion</div>nights of sleep tracked</div>'
          }
          continueButtonTitle="Next"
        />
      ),
    }),
    interstitial({
      category: 'Sleep Patterns',
      hideCategory: true,
      hideProgressBar: true,
      visibleIf: "{have_wearable} != 'Yes'",
      name: 'wearables_interstitial_no',
      component: () => (
        <RiseInterstitialTemplate
          heroImage={<img src={img_wearables_no} style={{maxHeight: '250px'}} />}
          headerText={`Use your phone to track sleep and get insights—no wearable needed`}
          bodyText={
            '<div class="text-large leading-loose" style="padding-left:22px; padding-right:22px;"><div class="bold text-x-large leading-tight">2.5 Billion</div>nights of sleep tracked</div>'
          }
          continueButtonTitle="Next"
        />
      ),
    }),
    radioPage({
      category: 'Sleep Patterns',
      name: 'consistency_throughout_the_week',
      title: 'Do you have a consistent sleep schedule throughout the week?',
      description: 'Maintaining a consistent sleep schedule improves sleep quality and energy levels',
      choices: ['It varies', 'Mainly consistent, but varies on weekends', 'Consistent across weekdays & weekends'],
      autoAdvance: true,
      isRequired: true,
    }),
    radioPage({
      category: 'Sleep Patterns',
      name: 'bedtime_routines',
      title: 'When did you typically go to bed in the last 2 weeks?',
      description: "We'll use this to determine how much sleep your body is getting",
      choices: ['Before 10 PM', '10-11 PM', '11 PM-12 AM', '12-1 AM', 'After 1 AM', 'Varied'],
      autoAdvance: true,
      isRequired: true,
    }),

    radioPage({
      category: 'Sleep Patterns',
      name: 'sleep_patterns',
      title: 'When did you typically wake up in the last 2 weeks?',
      description: "We'll use this to determine how much sleep your body is getting",
      choices: ['Before 6 AM', '6-7 AM', '7-8 AM', '8-9 AM', '9-10 AM', 'After 10 AM'],
      autoAdvance: true,
      isRequired: true,
    }),

    radioPage({
      category: 'Sleep Patterns',
      name: 'sleep_consistency',
      title: 'If you had complete freedom, when would you ideally wake up?',
      description: "We'll use this to understand how your sleep changes based on your schedule.",
      choices: ['Before 6 AM', '6-7 AM', '7-8 AM', '8-9 AM', '9-10 AM', 'After 10 AM'],
      autoAdvance: true,
      isRequired: true,
    }),

    interstitial({
      category: 'Sleep Patterns',
      name: 'isi_snapshot_interstitial1',
      component: () => <ISISnapshotInterstitial1of3 />,
    }),
    interstitial({
      category: 'Sleep Patterns',
      name: 'isi_snapshot_interstitial2',
      component: () => <ISISnapshotInterstitial2of3 />,
    }),
    interstitial({
      category: 'Sleep Patterns',
      name: 'isi_snapshot_interstitial3',
      component: () => <ISISnapshotInterstitial3of3 />,
    }),
    interstitial({
      category: 'Sleep Patterns',
      name: 'isi_snapshot_interstitial_plan',
      component: () => <ISISnapshotInterstitialPlan />,
    }),
    /**
     * Category: Attitudes & Beliefs
     */
    /** Start Sleep Quiz */
    interstitial({
      name: 'sleep_quiz_intro_interstitial',
      category: 'Attitudes & Beliefs',
      component: () => (
        <RiseInterstitialTemplate
          heroImage={<img src={img_sleep_beliefs_quiz_illustration} style={{maxHeight: '200px'}} />}
          headerText={
            <div style={{fontWeight: 'normal', textAlign: 'left'}}>
              Finally, let’s find the best approach based on your <strong>attitudes and beliefs about sleep</strong>
            </div>
          }
          continueButtonTitle="Next"
        />
      ),
    }),

    radioPage({
      name: 'sleep_quiz_8_hours_sleep_question',
      title: 'Everyone needs 8 hours of sleep to function well',
      choices: ['Agree', 'Disagree'],
      category: 'Attitudes & Beliefs',
      autoAdvance: true,
      isRequired: true,
    }),
    interstitial({
      category: 'Attitudes & Beliefs',
      name: 'sleep_quiz_8_hours_sleep_science',
      component: () => (
        <RiseInterstitialTemplate
          headerText={<div style={{textAlign: 'left'}}>What the science says</div>}
          bodyText={
            <InfoBox
              title="Sleep needs vary person to person, influenced by genetics and lifestyle."
              body="Most adults need between 7-9 hours, but the optimal amount can vary. We’ll look at your data and tell you how much sleep your body needs."
            />
          }
          continueButtonTitle="Next"
        />
      ),
    }),

    radioPage({
      name: 'sleep_quiz_try_harder_question',
      title: "If you can't sleep, you should stay in bed and try harder",
      choices: ['Agree', 'Disagree'],
      category: 'Attitudes & Beliefs',
      autoAdvance: true,
      isRequired: true,
    }),
    interstitial({
      category: 'Attitudes & Beliefs',
      name: 'sleep_quiz_try_harder_science',
      component: () => (
        <RiseInterstitialTemplate
          headerText={<div style={{textAlign: 'left'}}>What the science says</div>}
          bodyText={
            <InfoBox
              title="Staying in bed while awake can lead to frustration and make the bed a cue for wakefulness rather than sleep. "
              body="It’s better to leave the bedroom and do something relaxing until you feel sleepy. RISE has science backed relaxation techniques, like autogenic training to help you fall asleep. "
            />
          }
          continueButtonTitle="Next"
        />
      ),
    }),
    radioPage({
      name: 'sleep_quiz_weekend_sleep_question',
      title: "It's okay to skimp on sleep during the week and catch up during the weekend",
      choices: ['Agree', 'Disagree'],
      category: 'Attitudes & Beliefs',
      autoAdvance: true,
      isRequired: true,
    }),
    interstitial({
      category: 'Attitudes & Beliefs',
      name: 'sleep_quiz_weekend_sleep_science',
      component: () => (
        <RiseInterstitialTemplate
          headerText={<div style={{textAlign: 'left'}}>What the science says</div>}
          bodyText={
            <InfoBox
              title="Inconsistent sleep patterns can disrupt your circadian rhythm and worsen overall sleep quality."
              body="Maintaining a regular sleep schedule is crucial for optimal health."
            />
          }
          continueButtonTitle="Next"
        />
      ),
    }),

    radioPage({
      name: 'sleep_quiz_alcohol_sleep_question',
      title: 'Alcohol helps you sleep',
      choices: ['Agree', 'Disagree'],
      category: 'Attitudes & Beliefs',
      autoAdvance: true,
      isRequired: true,
    }),
    interstitial({
      category: 'Attitudes & Beliefs',
      name: 'sleep_quiz_alcohol_sleep_science',
      component: () => (
        <RiseInterstitialTemplate
          headerText={<div style={{textAlign: 'left'}}>What the science says</div>}
          bodyText={
            <InfoBox
              title="Alcohol might help you fall asleep faster but it significantly reduces the quality of sleep, particularly by reducing REM sleep, which is crucial for memory and learning."
              body="RISE can remind you when to cut off alcohol to reduce negative impact on sleep."
            />
          }
          continueButtonTitle="Next"
        />
      ),
    }),

    radioPage({
      name: 'sleep_quiz_caffeine_sleep_question',
      title: 'Caffeine is bad for sleep',
      choices: ['Agree', 'Disagree'],
      category: 'Attitudes & Beliefs',
      autoAdvance: true,
      isRequired: true,
    }),
    interstitial({
      category: 'Attitudes & Beliefs',
      name: 'sleep_quiz_caffeine_sleep_science',
      component: () => (
        <RiseInterstitialTemplate
          headerText={<div style={{textAlign: 'left'}}>What the science says</div>}
          bodyText={
            <InfoBox
              title="Surprisingly, it depends on the timing. Caffeine has a half-life of about 6 hours, which means it takes about 6 hours for half of the caffeine to be eliminated from your body."
              body="To minimize caffeine’s impact on sleep, avoid caffeine at least 6 hours before bedtime. RISE can send you custom reminders based on your optimal bedtime."
            />
          }
          continueButtonTitle="Next"
        />
      ),
    }),
    // Results
    interstitial({
      name: 'sleep_quiz_results',
      category: 'Attitudes & Beliefs',
      component: () => <ResultInterstitial />,
    }),

    /*
     * Category: My Results
     */
    textPage({
      category: 'My Results',
      name: 'person_name',
      title: 'What is your name?',
      description: 'We’ll use this to personalize your experience',
      placeholder: 'Enter name',
      isRequired: true,
    }),
    interstitial({
      category: 'My Results',
      hideCategory: true,
      hideProgressBar: true,
      name: 'analyze_interstitial_v2',
      component: AnalyzeInterstitialV2,
    }),
    interstitial({
      category: 'My Results',
      name: 'account_creation_interstitial',
      component: AccountCreationInterstitial,
    }),

    interstitial({
      category: 'My Results',
      name: 'plan_teaser_interstitial',
      component: PlanTeaser,
    }),
    interstitial({
      category: 'My Results',
      name: 'debt_reduction_interstitial',
      component: () => (
        <RiseInterstitialTemplate
          heroImage={<img src={progress_chart_v2_img} height={202} />}
          headerText={`<div class="left">Increase energy with RISE vs. trying on your own</div>`}
          bodyText={`More active RISE users reduce twice as much Sleep Debt as less active users`}
          continueButtonTitle="Next"
        />
      ),
    }),
  ],
};

export default survey_config;
