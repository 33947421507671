/**
 * Remove Zero Width Spaces
 * Those are used to work around certain problems with allowing a nullish selection
 * in Survey JS
 */
function removeZeroWidthSpaces(input: string) {
  return input.replace(/\u200B/g, '');
}

export const getCircadianFromMidpoints = (
  bedtimeMidpointMidnightOffset: string,
  wakeMidpointMidnightOffset: string,
) => {
  /** rules
   * Calculate Sleep midpoint from bed and wake times
   * IF
   * Midpoint: <= 3:00 AM = early
   * Midpoint: > 3:00 AM = late
   */
  // Remove Zero Width Spaces
  const cleanedBedtimeMidpointMidnightOffset = removeZeroWidthSpaces(bedtimeMidpointMidnightOffset);
  const cleanedWakeMidpointMidnightOffset = removeZeroWidthSpaces(wakeMidpointMidnightOffset);

  // If missing data, return early as default
  if (cleanedBedtimeMidpointMidnightOffset === '' || cleanedWakeMidpointMidnightOffset === '') {
    return 'early';
  }

  const bedtime = parseFloat(cleanedBedtimeMidpointMidnightOffset);
  const wake = parseFloat(cleanedWakeMidpointMidnightOffset);

  // Calculate sleep midpoint
  // IMPORTANT: In this format, positive bedtime means hours BEFORE midnight
  // and negative bedtime means hours AFTER midnight
  // Wake time is always hours AFTER midnight

  // For the midpoint calculation:
  // 1. Convert bedtime to hours from midnight (negative if after midnight)
  const bedtimeHoursFromMidnight = bedtime > 0 ? -bedtime : Math.abs(bedtime);

  // 2. Calculate midpoint as the average of bedtime hours from midnight and wake hours from midnight
  const sleepMidpointHoursFromMidnight = (bedtimeHoursFromMidnight + wake) / 2;

  // 3. Determine circadian type based on sleep midpoint
  // If midpoint is <= 3 hours after midnight (3:00 AM), it's early
  // If midpoint is > 3 hours after midnight, it's late
  console.log(
    'bedtimeMidpointMidnightOffset',
    bedtimeMidpointMidnightOffset,
    'wakeMidpointMidnightOffset',
    wakeMidpointMidnightOffset,
    'sleepMidpointHoursFromMidnight',
    sleepMidpointHoursFromMidnight,
  );
  if (sleepMidpointHoursFromMidnight <= 3) {
    return 'early';
  } else {
    return 'late';
  }
};
