export const PriceSuperScript: React.FC<{
  price: number;
  symbol: string;
  symbolAtStart: boolean;
  style?: React.CSSProperties;
}> = ({price, symbol, symbolAtStart, style}) => {
  return (
    <div style={{display: 'flex', flexDirection: symbolAtStart ? 'row' : 'row-reverse', ...style}}>
      <span style={{verticalAlign: 'super', fontSize: '10px'}}>{symbol}</span>
      {price}
    </div>
  );
};
