import styles from './OfferExpirationTimer.module.css';
import stopwatch from './stopwatch.svg';
import {TimerText} from './TimerText';

export const OfferExpirationTimer = ({timerDuration}: {timerDuration: number}) => {
  return (
    <div className={styles.container}>
      <img src={stopwatch} alt="Stopwatch" className={styles.image} />
      <TimerText timerDuration={timerDuration} />
    </div>
  );
};
