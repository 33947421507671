import React, {useEffect, useState} from 'react';
import {RiseInterstitialProps} from 'screens/RiseSurvey/components/RiseInterstitialTemplate/RiseInterstitialBase';
import RiseSurveyInterstitialControls from 'screens/RiseSurvey/components/RiseSurveyControls/RiseSurveyInterstitialControls';
import multiplier2x from './assets/2x.png';
import donutLow from './assets/donut_low.png';
import donutModerate from './assets/donut_moderate.png';
import donutHigh from './assets/donut_high.png';
import LoadingState from './LoadingState';
import styles from './ISISnapshotInterstitial.module.css';
import {surveyInstance} from 'screens/RiseSurvey/RiseSurvey';
import {getSleepDebtZoneFromMidpointOffsets} from 'utils/getSleepDebtZoneFromMidpointOffsets';
import {Card} from './Card';

const variantMap = {
  low: {
    chart: donutLow,
    style: styles.low,
    text: 'low',
  },
  moderate: {
    chart: donutModerate,
    style: styles.moderate,
    text: 'moderate',
  },
  high: {
    chart: donutHigh,
    style: styles.high,
    text: 'high',
  },
};

const ResultContainer: React.FC<{sleepDebtLevel: 'low' | 'moderate' | 'high'}> = ({sleepDebtLevel = 'moderate'}) => {
  const {style, chart, text} = variantMap[sleepDebtLevel] ?? variantMap.moderate;
  return (
    <Card
      badge={multiplier2x}
      bannerText={'Active RISE users reduce twice as much Sleep Debt as less active users.'}
      style={style}
      body={
        <>
          <img src={chart} alt="donut" className={styles.donutChart} />
          <div className={styles.resultsText}>
            Based on what you told us, your estimated debt is <span className={styles.bold}>{text}</span>
          </div>
        </>
      }
    />
  );
};

const ISISnapshotInterstitial1of3: React.FC<RiseInterstitialProps> = () => {
  const [loading, setLoading] = useState(true);
  const [fadeOut, setFadeOut] = useState(false);

  // Function to determine sleep debt level from survey data
  const getSleepDebtLevel = (): 'low' | 'moderate' | 'high' => {
    const sleepDebtZone = getSleepDebtZoneFromMidpointOffsets(
      surveyInstance.getVariable('bedtime_midpoint_midnight_offset'),
      surveyInstance.getVariable('wake_midpoint_midnight_offset'),
    );
    return sleepDebtZone;
  };

  const sleepDebtLevel = getSleepDebtLevel();

  useEffect(() => {
    // Start fade out
    const fadeTimer = setTimeout(() => {
      setFadeOut(true);
    }, 5000);

    // Complete transition to results screen
    const loadingTimer = setTimeout(() => {
      setLoading(false);
    }, 5300);

    return () => {
      clearTimeout(fadeTimer);
      clearTimeout(loadingTimer);
    };
  }, []);

  // Loading screen
  if (loading) {
    return <LoadingState fadeOut={fadeOut} duration={5000} />;
  }

  // Results screen
  return (
    <div className={`${styles.container} ${styles.fadeIn}`}>
      <div className={styles.counter}>1/3</div>
      <div className={styles.title}>Sleep Debt</div>
      <div className={styles.description}>
        Sleep Debt is the gap between the sleep your body needs and the sleep it gets
      </div>

      <ResultContainer sleepDebtLevel={sleepDebtLevel} />

      <RiseSurveyInterstitialControls continueButtonTitle="Next" />
    </div>
  );
};

export default ISISnapshotInterstitial1of3;
