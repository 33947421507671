import {TimerText} from 'screens/CheckoutScreens/RunwayerCheckoutScreen/components/OfferExpirationTimer/TimerText';
import {DiscountButton} from '../Buttons/DiscountButton';
import stopwatch_orange from './assets/stopwatch-orange.svg';
import styles from './SavedDiscountBanner.module.css';

export const SavedDiscountBanner = ({
  onButtonClick,
  timerDuration,
}: {
  onButtonClick: () => void;
  timerDuration: number;
}) => (
  <section className={styles.container}>
    <div style={{display: 'flex', flexDirection: 'row', alignItems: 'flex-start', gap: '8px', paddingRight: '12px'}}>
      <img src={stopwatch_orange} alt="Stopwatch" />
      <TimerText timerDuration={timerDuration} />
    </div>
    <div>
      <DiscountButton title="Get discount" onClick={onButtonClick} />
    </div>
  </section>
);
