import Environment from './Environment';

export type PlanInfo = {
  /** Price to compare against. Used in winback. */
  comparePrice?: number;
  price: number;
  introPrice: number;
  perDay: number;
  percentOff: number | null;
  label: string;
  id: string;
  purchasePrice: number;
  duration: string;
  frequency: string;
  introDuration: string;
  currency: SupportedCurrency;
  product: StripeProducts;
  payPalPlanId: string;
  trial_duration: number;
};

export type SupportedCountryCode = 'US' | 'CA' | 'GB' | 'AU' | 'EU';

type SupportedCurrency = 'usd' | 'cad' | 'gbp' | 'aud' | 'eur';

type StripeProducts =
  | 'web_acquisition_week'
  | 'web_acquisition_month'
  | 'web_acquisition_quarter'
  | 'web_acquisition_7d_quarterly_30'
  | 'web_acquisition_7d_monthly_20';

const inProduction = Environment.isProduction;

const calcPercentOff = (comparePrice: number | undefined, price: number) => {
  if (!comparePrice) return 0;
  return (comparePrice - price) / comparePrice;
};

export const weekPlan: Record<SupportedCountryCode, PlanInfo> = {
  AU: {
    price: 44.99,
    introPrice: 10.99,
    perDay: 1.57,
    percentOff: 77,
    label: 'A$',
    id: inProduction ? 'price_1Q2HZwH7jib4x1GyLOH4D7yM' : 'price_1PzjNMH7jib4x1Gym8xcx361',
    purchasePrice: 1099,
    frequency: 'Weekly',
    duration: 'month',
    introDuration: '1 week',
    trial_duration: 7,
    currency: 'aud',
    product: 'web_acquisition_week',
    payPalPlanId: 'P-785740446W877063FM35NNXQ',
    // runwayer payPalPlanId: 'P-3WN75667YX064833FMZPAOII',
  },
  GB: {
    price: 23.99,
    introPrice: 5.99,
    perDay: 0.86,
    percentOff: 77,
    label: '£',
    id: inProduction ? 'price_1Q2HZvH7jib4x1GyTH0P7FB9' : 'price_1PzjNLH7jib4x1GykjMdt6Bb',
    purchasePrice: 599,
    frequency: 'Weekly',
    duration: 'month',
    introDuration: '1 week',
    trial_duration: 7,
    currency: 'gbp',
    product: 'web_acquisition_week',
    payPalPlanId: 'P-25B97445UX507703EM35NPAA',
    // runwayer payPalPlanId: 'P-8UE83171465288448MZPAMIY',
  },
  CA: {
    price: 39.99,
    introPrice: 9.99,
    perDay: 1.43,
    percentOff: 77,
    label: 'C$',
    id: inProduction ? 'price_1Q2HZvH7jib4x1Gya6rr4kx0' : 'price_1PzjNLH7jib4x1GyuNRwsMHM',
    purchasePrice: 999,
    frequency: 'Weekly',
    duration: 'month',
    introDuration: '1 week',
    trial_duration: 7,
    currency: 'cad',
    product: 'web_acquisition_week',
    payPalPlanId: 'P-75032166AH942844HM35NQBA',
    // runwayer payPalPlanId: 'P-6GH39186YH2710410MZPAPQI',
  },
  US: {
    price: 29.99,
    introPrice: 6.99,
    perDay: 1,
    percentOff: 77,
    label: '$',
    id: inProduction ? 'price_1Q2HZvH7jib4x1GyqNyvtdU3' : 'price_1PzjNLH7jib4x1Gyox9lzNKi',
    purchasePrice: 699,
    frequency: 'Weekly', // TODO: if we get to translations - make this a lookup key vs the string (and lookup in component)
    duration: 'month',
    introDuration: '1 week',
    trial_duration: 7,
    currency: 'usd',
    product: 'web_acquisition_week',
    payPalPlanId: 'P-80V72673CT033562DM35NRFQ',
    // runwayer payPalPlanId: 'P-98G47623Y89150800MZPAIDI',
  },
  EU: {
    price: 29.99,
    introPrice: 6.99,
    perDay: 1,
    percentOff: 77,
    label: '€',
    id: inProduction ? 'price_1QlzAGH7jib4x1GymkjaXzKV' : 'price_1QlvPYH7jib4x1GyRnHcOgOL', // TODO: CHANGE
    purchasePrice: 699,
    frequency: 'Weekly',
    duration: 'month',
    introDuration: '1 week',
    trial_duration: 7,
    currency: 'eur',
    product: 'web_acquisition_week',
    payPalPlanId: 'P-51W86079AX591983MM6L7G4I',
  },
};

export const monthPlan: Record<SupportedCountryCode, PlanInfo> = {
  AU: {
    price: 44.99,
    introPrice: 29.99,
    perDay: 1,
    percentOff: 33,
    label: 'A$',
    id: inProduction ? 'price_1Q2HaGH7jib4x1GyF610xfeZ' : 'price_1PzjNsH7jib4x1GysmsZ74gy',
    purchasePrice: 2999,
    frequency: 'Monthly',
    duration: 'month',
    introDuration: '1 month',
    trial_duration: 30,
    currency: 'aud',
    product: 'web_acquisition_month',
    payPalPlanId: 'P-4UK69520HE803161MM35NSOA',
    // runwayer payPalPlanId: 'P-24902817V0307741XMZPAOYY',
  },
  GB: {
    price: 23.99,
    introPrice: 15.99,
    perDay: 0.53,
    percentOff: 33,
    label: '£',
    id: inProduction ? 'price_1Q2HaGH7jib4x1GysOttxQdP' : 'price_1PzjNsH7jib4x1GyeAJ63EWj',
    purchasePrice: 1599,
    frequency: 'Monthly',
    duration: 'month',
    introDuration: '1 month',
    trial_duration: 30,
    currency: 'gbp',
    product: 'web_acquisition_month',
    payPalPlanId: 'P-0A528514040276333M35NTCQ',
    // runwayer payPalPlanId: 'P-8HT630535G2283148MZPAM5Q',
  },
  CA: {
    price: 39.99,
    introPrice: 26.99,
    perDay: 0.9,
    percentOff: 33,
    label: 'C$',
    id: inProduction ? 'price_1Q2HaGH7jib4x1Gyct7Wxlvf' : 'price_1PzjNrH7jib4x1GyoPeTs7mY',
    purchasePrice: 2699,
    frequency: 'Monthly',
    duration: 'month',
    introDuration: '1 month',
    trial_duration: 30,
    currency: 'cad',
    product: 'web_acquisition_month',
    payPalPlanId: 'P-6U793835NC6199612M35QGFY',
    // runwayer payPalPlanId: 'P-5PS70357XE605544DMZPAP2A',
  },
  US: {
    price: 29.99,
    introPrice: 19.99,
    perDay: 0.67,
    percentOff: 33,
    label: '$',
    id: inProduction ? 'price_1Q2HaFH7jib4x1GyDBxe1amV' : 'price_1PzjNrH7jib4x1GyGVxXczHC',
    purchasePrice: 1999,
    frequency: 'Monthly',
    duration: 'month',
    introDuration: '1 month',
    trial_duration: 30,
    currency: 'usd',
    product: 'web_acquisition_month',
    payPalPlanId: 'P-9BE96908P23979335M35NUIA',
    // runwayer payPalPlanId: 'P-4M1563150S375270KMZPAJDQ',
  },
  EU: {
    price: 29.99,
    introPrice: 19.99,
    perDay: 0.67,
    percentOff: 33,
    label: '€',
    id: inProduction ? 'price_1QlzARH7jib4x1GyDsqbFQez' : 'price_1QlvQKH7jib4x1GyKCeKc6TU',
    purchasePrice: 1999,
    frequency: 'Monthly',
    duration: 'month',
    introDuration: '1 month',
    trial_duration: 30,
    currency: 'eur',
    product: 'web_acquisition_month',
    payPalPlanId: 'P-05V80764F1186422WM6L7HOQ',
  },
};

export const quarterPlan: Record<SupportedCountryCode, PlanInfo> = {
  AU: {
    price: 69.99,
    introPrice: 44.99,
    perDay: 0.49,
    percentOff: 40,
    label: 'A$',
    id: inProduction ? 'price_1Q2HaNH7jib4x1GyP2xaAlKc' : 'price_1PzjOSH7jib4x1GyFgmpp9BO',
    purchasePrice: 4499,
    frequency: 'Quarterly',
    duration: 'quarter',
    introDuration: 'a quarter',
    trial_duration: 90,
    currency: 'aud',
    product: 'web_acquisition_quarter',
    payPalPlanId: 'P-1EJ1620345645241EM35N2OQ',
    // runwayer payPalPlanId: 'P-3SR96799GC793003XMZPAPFY',
  },
  GB: {
    price: 39.99,
    introPrice: 23.99,
    perDay: 0.26,
    percentOff: 40,
    label: '£',
    id: inProduction ? 'price_1Q2HaMH7jib4x1GydRZ2Er9s' : 'price_1PzjORH7jib4x1GyzoaPNkmM',
    purchasePrice: 2399,
    frequency: 'Quarterly',
    duration: 'quarter',
    introDuration: 'a quarter',
    trial_duration: 90,
    currency: 'gbp',
    product: 'web_acquisition_quarter',
    payPalPlanId: 'P-75967049867063901M35N3BI',
    // runwayer payPalPlanId: 'P-09P17091YA162804SMZPANJY',
  },
  CA: {
    price: 69.99,
    introPrice: 39.99,
    perDay: 0.44,
    percentOff: 40,
    label: 'C$',
    id: inProduction ? 'price_1Q2HaMH7jib4x1GynlPkY46b' : 'price_1PzjORH7jib4x1GyWzz3ypKW',
    purchasePrice: 3999,
    frequency: 'Quarterly',
    duration: 'quarter',
    introDuration: 'a quarter',
    trial_duration: 90,
    currency: 'cad',
    product: 'web_acquisition_quarter',
    payPalPlanId: 'P-2DV00477H6861933HM35N3SY',
    // runwayer payPalPlanId: 'P-24444615T1380040UMZPAQEI',
  },
  US: {
    price: 49.99,
    introPrice: 29.99,
    perDay: 0.33,
    percentOff: 40,
    label: '$',
    id: inProduction ? 'price_1Q2HaMH7jib4x1GymUS9QTvE' : 'price_1PzjORH7jib4x1GyPrqSfXIH',
    purchasePrice: 2999,
    frequency: 'Quarterly',
    duration: 'quarter',
    introDuration: 'a quarter',
    trial_duration: 90,
    currency: 'usd',
    product: 'web_acquisition_quarter',
    payPalPlanId: 'P-1L66894790428054SM35N4HA',
    // runwayer payPalPlanId: 'P-9SM47879VF842110XMZPAJSA',
  },
  EU: {
    price: 49.99,
    introPrice: 29.99,
    perDay: 0.33,
    percentOff: 40,
    label: '€',
    id: inProduction ? 'price_1QlzAaH7jib4x1GyxgfG66pu' : 'price_1QlvQZH7jib4x1GynaLnFVQf',
    purchasePrice: 2999,
    frequency: 'Quarterly',
    duration: 'quarter',
    introDuration: 'a quarter',
    trial_duration: 90,
    currency: 'eur',
    product: 'web_acquisition_quarter',
    payPalPlanId: 'P-4HX97631UW0065218M6L7H7Q',
  },
};

export const config7dQuarterly30: Record<SupportedCountryCode, PlanInfo> = {
  AU: {
    comparePrice: 69.99, // the previously seen quarterly price
    price: 44.99,
    introPrice: 1.0,
    get perDay() {
      return this.price / 90;
    },
    get percentOff() {
      return calcPercentOff(this.comparePrice, this.price);
    },
    label: 'A$',
    id: inProduction ? 'price_1QhFwBH7jib4x1GynhjzURxJ' : 'price_1QhFuiH7jib4x1GyQNPxZmnh',
    purchasePrice: 100,
    frequency: 'Quarterly',
    duration: 'quarter',
    // TODO: introDuration may not be used in a winback context. Make sure to double-check value before using
    introDuration: '1 week',
    trial_duration: 7,
    currency: 'aud',
    product: 'web_acquisition_7d_quarterly_30',
    payPalPlanId: 'P-8DX00532G7757502KM6DL6SI',
  },
  GB: {
    comparePrice: 39.99, // the previously seen quarterly price
    price: 23.99,
    introPrice: 1.0,
    get perDay() {
      return this.price / 90;
    },
    get percentOff() {
      return calcPercentOff(this.comparePrice, this.price);
    },
    label: '£',
    id: inProduction ? 'price_1QhFwAH7jib4x1GyE1gvhjPE' : 'price_1QhFuiH7jib4x1Gyrln3FT4h',
    purchasePrice: 100,
    frequency: 'Quarterly',
    duration: 'quarter',
    // TODO: introDuration may not be used in a winback context. Make sure to double-check value before using
    introDuration: '1 week',
    trial_duration: 7,
    currency: 'gbp',
    product: 'web_acquisition_7d_quarterly_30',
    payPalPlanId: 'P-95F260128V247384PM6DL6JA',
  },
  CA: {
    comparePrice: 69.99, // the previously seen quarterly price
    price: 39.99,
    introPrice: 1.0,
    get perDay() {
      return this.price / 90;
    },
    get percentOff() {
      return calcPercentOff(this.comparePrice, this.price);
    },
    label: 'C$',
    id: inProduction ? 'price_1QhFwAH7jib4x1GygiZYxQED' : 'price_1QhFuiH7jib4x1GyjsIDrOQ4',
    purchasePrice: 100,
    frequency: 'Quarterly',
    duration: 'quarter',
    // TODO: introDuration may not be used in a winback context. Make sure to double-check value before using
    introDuration: '1 week',
    trial_duration: 7,
    currency: 'cad',
    product: 'web_acquisition_7d_quarterly_30',
    payPalPlanId: 'P-2E570956MB552505BM6DL57Y',
  },
  US: {
    comparePrice: 49.99, // the previously seen quarterly price
    price: 29.99,
    introPrice: 1.0,
    get perDay() {
      return this.price / 90;
    },
    get percentOff() {
      return calcPercentOff(this.comparePrice, this.price);
    },
    label: '$',
    id: inProduction ? 'price_1QhFwAH7jib4x1GyQdoeBT4M' : 'price_1QhFuhH7jib4x1Gy6Jk8VYzH',
    purchasePrice: 100,
    frequency: 'Quarterly',
    duration: 'quarter',
    // TODO: introDuration may not be used in a winback context. Make sure to double-check value before using
    introDuration: '1 week',
    trial_duration: 7,
    currency: 'usd',
    product: 'web_acquisition_7d_quarterly_30',
    payPalPlanId: 'P-32S67309JM5834004M6DL5YA',
  },
  EU: {
    comparePrice: 49.99, // the previously seen quarterly price
    price: 29.99,
    introPrice: 1.0,
    get perDay() {
      return this.price / 90;
    },
    get percentOff() {
      return calcPercentOff(this.comparePrice, this.price);
    },
    label: '€',
    id: inProduction ? 'price_1QhFwBH7jib4x1GyNhvk0Fso' : 'price_1QhFuiH7jib4x1GyYDxL8MPS',
    purchasePrice: 100,
    frequency: 'Quarterly',
    duration: 'quarter',
    // TODO: introDuration may not be used in a winback context. Make sure to double-check value before using
    introDuration: '1 week',
    trial_duration: 7,
    currency: 'eur',
    product: 'web_acquisition_7d_quarterly_30',
    payPalPlanId: 'P-9BH390624Y684744FM6L7J4Y',
  },
};

export const config7dMonthly20: Record<SupportedCountryCode, PlanInfo> = {
  AU: {
    comparePrice: 44.99, // the previously seen monthly price
    price: 29.99,
    introPrice: 1.0,
    get perDay() {
      return this.price / 30;
    },
    get percentOff() {
      return calcPercentOff(this.comparePrice, this.price);
    },
    label: 'A$',
    id: inProduction ? 'price_1QhFw1H7jib4x1GyVMLmeWQh' : 'price_1QhFuVH7jib4x1GyxCtGko3x',
    purchasePrice: 100,
    frequency: 'Monthly',
    duration: 'month',
    // TODO: introDuration may not be used in a winback context. Make sure to double-check value before using
    introDuration: '1 week',
    trial_duration: 7,
    currency: 'aud',
    product: 'web_acquisition_7d_monthly_20',
    payPalPlanId: 'P-2FL324304E801615AM6DL5DY',
  },
  GB: {
    comparePrice: 23.99, // the previously seen monthly price
    price: 15.99,
    introPrice: 1.0,
    get perDay() {
      return this.price / 30;
    },
    get percentOff() {
      return calcPercentOff(this.comparePrice, this.price);
    },
    label: '£',
    id: inProduction ? 'price_1QhFw1H7jib4x1GyAQHCnWv5' : 'price_1QhFuVH7jib4x1GyEg5vB8nS',
    purchasePrice: 100,
    frequency: 'Monthly',
    duration: 'month',
    // TODO: introDuration may not be used in a winback context. Make sure to double-check value before using
    introDuration: '1 week',
    trial_duration: 7,
    currency: 'gbp',
    product: 'web_acquisition_7d_monthly_20',
    payPalPlanId: 'P-20V33303MG908250AM6DL4ZA',
  },
  CA: {
    comparePrice: 39.99, // the previously seen monthly price
    price: 26.99,
    introPrice: 1.0,
    get perDay() {
      return this.price / 30;
    },
    get percentOff() {
      return calcPercentOff(this.comparePrice, this.price);
    },
    label: 'C$',
    id: inProduction ? 'price_1QhFw1H7jib4x1GyOJ9zxird' : 'price_1QhFuVH7jib4x1GyWNMZYczt',
    purchasePrice: 100,
    frequency: 'Monthly',
    duration: 'month',
    // TODO: introDuration may not be used in a winback context. Make sure to double-check value before using
    introDuration: '1 week',
    trial_duration: 7,
    currency: 'cad',
    product: 'web_acquisition_7d_monthly_20',
    payPalPlanId: 'P-7L30761232232394WM6DL4PY',
  },
  US: {
    comparePrice: 29.99, // the previously seen monthly price
    price: 19.99,
    introPrice: 1.0,
    get perDay() {
      return this.price / 30;
    },
    get percentOff() {
      return calcPercentOff(this.comparePrice, this.price);
    },
    label: '$',
    id: inProduction ? 'price_1QhFw1H7jib4x1GyeYDjVH2q' : 'price_1QhFuVH7jib4x1GyoEkvl8fN',
    purchasePrice: 100,
    frequency: 'Monthly',
    duration: 'month',
    // TODO: introDuration may not be used in a winback context. Make sure to double-check value before using
    introDuration: '1 week',
    trial_duration: 7,
    currency: 'usd',
    product: 'web_acquisition_7d_monthly_20',
    payPalPlanId: 'P-0W858980JG224915SM6DL35I',
  },
  EU: {
    comparePrice: 29.99, // the previously seen monthly price
    price: 19.99,
    introPrice: 1.0,
    get perDay() {
      return this.price / 30;
    },
    get percentOff() {
      return calcPercentOff(this.comparePrice, this.price);
    },
    label: '€',
    id: inProduction ? 'price_1QhFw2H7jib4x1GywXTNcLyO' : 'price_1QhFuWH7jib4x1GyBdrvqY4L',
    purchasePrice: 100,
    frequency: 'Monthly',
    duration: 'month',
    // TODO: introDuration may not be used in a winback context. Make sure to double-check value before using
    introDuration: '1 week',
    trial_duration: 7,
    currency: 'eur',
    product: 'web_acquisition_7d_monthly_20',
    payPalPlanId: 'P-1AN85454V0633912AM6DL5NY',
  },
};
