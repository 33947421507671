import React from 'react';
import styles from './Card.module.css';

export const Card = ({
  badge,
  bannerText,
  style,
  body,
}: {
  badge: string;
  bannerText: string;
  style: string;
  body: React.ReactNode;
}) => {
  return (
    <div className={[styles.container, style].join(' ')}>
      {body}
      <div className={styles.divider} />
      <div className={styles.banner}>
        <img src={badge} alt="multiplier" className={styles.badge} />
        <div className={styles.bannerText}>{bannerText}</div>
      </div>
    </div>
  );
};
