import React, {useEffect} from 'react';
import {surveyInstance} from 'screens/RiseSurvey/RiseSurvey';
import {RiseInterstitialProps} from 'screens/RiseSurvey/components/RiseInterstitialTemplate/RiseInterstitialBase';
import RiseSurveyInterstitialControls from 'screens/RiseSurvey/components/RiseSurveyControls/RiseSurveyInterstitialControls';
import Analytics from 'utils/Analytics';
import {Card} from './Card';
import {ISIScale} from './ISIScale';
import styles from './ISISnapshotInterstitial.module.css';
import zzzAtRisk from './assets/zzz_icon_at_risk.svg';
import zzzCritical from './assets/zzz_icon_critical.svg';
import zzzHealthy from './assets/zzz_icon_healthy.svg';
import zzzPoor from './assets/zzz_icon_poor.svg';

const isiState = {
  healthy: {
    label: 'healthy',
    badge: zzzHealthy,
    cardStyle: styles.isiHealthyCard,
    bannerText: 'We’ll personalize your plan to maintain and improve your sleep.',
  },
  atRisk: {
    label: 'at risk',
    badge: zzzAtRisk,
    cardStyle: styles.isiAtRiskCard,
    bannerText: 'You reported mild insomnia symptoms. We’ll help you navigate this.',
  },
  poor: {
    label: 'poor',
    badge: zzzPoor,
    cardStyle: styles.isiPoorCard,
    bannerText: 'You reported moderate insomnia symptoms. We’ll help you navigate this.',
  },
  critical: {
    label: 'critical',
    badge: zzzCritical,
    cardStyle: styles.isiCriticalCard,
    bannerText: 'You reported severe insomnia symptoms. We’ll help you navigate this.',
  },
};

const getISIZone = (isiScore: number) => {
  if (isiScore >= 0 && isiScore <= 7) return 'healthy';
  if (isiScore >= 8 && isiScore <= 14) return 'atRisk';
  if (isiScore >= 15 && isiScore <= 21) return 'poor';
  if (isiScore >= 22 && isiScore <= 28) return 'critical';
  return 'healthy';
};

const ResultContainer: React.FC<{isiScore: number}> = ({isiScore}) => {
  const isiZone = getISIZone(isiScore);
  const content = isiState[isiZone];
  return (
    <Card
      badge={content.badge}
      bannerText={content.bannerText}
      style={content.cardStyle}
      body={
        <div>
          <ISIScale value={isiScore} />
          <div className={styles.resultsText} style={{marginTop: 20}}>
            Based on what you told us, your sleep quality is in the <span className={styles.bold}>{content.label}</span>{' '}
            range
          </div>
        </div>
      }
    />
  );
};

const ISISnapshotInterstitial3of3: React.FC<RiseInterstitialProps> = () => {
  const isi_falling_asleep_difficulty = parseInt(surveyInstance.getValue('isi_falling_asleep_difficulty') ?? '0');
  const isi_staying_asleep_difficulty = parseInt(surveyInstance.getValue('isi_staying_asleep_difficulty') ?? '0');
  const isi_early_waking = parseInt(surveyInstance.getValue('isi_early_waking') ?? '0');
  const isi_sleep_satisfaction = parseInt(surveyInstance.getValue('isi_sleep_satisfaction') ?? '0');
  const isi_daily_functioning = parseInt(surveyInstance.getValue('isi_daily_functioning') ?? '0');
  const isi_sleep_problems_noticeable = parseInt(surveyInstance.getValue('isi_sleep_problems_noticeable') ?? '0');
  const isi_sleep_worry = parseInt(surveyInstance.getValue('isi_sleep_worry') ?? '0');

  const isiScore =
    isi_falling_asleep_difficulty +
    isi_staying_asleep_difficulty +
    isi_early_waking +
    isi_sleep_satisfaction +
    isi_daily_functioning +
    isi_sleep_problems_noticeable +
    isi_sleep_worry;

  useEffect(() => {
    Analytics.identify({web_isi_score: isiScore.toString()});
  }, [isiScore]);

  return (
    <div className={`${styles.container} ${styles.fadeIn}`}>
      <div className={styles.counter}>3/3</div>
      <div className={styles.title}>Sleep Quality</div>
      <div className={styles.description}>
        We use the clinically validated Insomnia Severity Index to calculate your score
      </div>

      <ResultContainer isiScore={isiScore} />
      <RiseSurveyInterstitialControls continueButtonTitle="Next" />
    </div>
  );
};

export default ISISnapshotInterstitial3of3;
