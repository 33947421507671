import {
  RiseQuestionImagePicker,
  RiseQuestionRadio,
  RiseQuestionText,
  RiseSurveyPage,
  RiseQuestionCheckbox,
} from '../../../types';
import {getRandomDelay} from './getRandomDelay';

// TODO combine PageOptions and RadioOptions they are basically the same?
type PageOptions = {
  category?: string;
  /** How long to show the spinner for before moving to the next page */
  delayDuration?: 'random' | number | undefined;
  /** If true, the page will be skipped if the visibleIf condition is not met */
  visibleIf?: string;
  autoAdvance?: boolean;
  hideProgressBar?: boolean;
  hideCategory?: boolean;
  identifyPropertyName?: string;
};

export const page = (
  question: RiseQuestionRadio | RiseQuestionText | RiseQuestionImagePicker | RiseQuestionCheckbox,
  options: PageOptions = {},
): RiseSurveyPage => {
  return {
    elements: [question],
    visibleIf: options.visibleIf,
    riseProperties: {
      category: options.category,
      delayOnNext: options.delayDuration === 'random' ? getRandomDelay() : (options.delayDuration ?? 0),
      autoAdvance: options.autoAdvance,
      hideProgressBar: options.hideProgressBar,
      hideCategory: options.hideCategory,
      identifyPropertyName: options.identifyPropertyName,
    },
  };
};

type TextOptions = Omit<RiseQuestionText, 'type'> & {
  /** How long to show the spinner for before moving to the next page. `random` will show a random delay between 1 and 5 seconds. */
  delay?: 'random' | number | undefined;
  /** If true, the page will be skipped if the visibleIf condition is not met */
  visibleIf?: string;
  category?: string;
  hideProgressBar?: boolean;
  hideCategory?: boolean;
};

type RadioOptions = Omit<RiseQuestionRadio, 'type'> & {
  /** How long to show the spinner for before moving to the next page. `random` will show a random delay between 1 and 5 seconds. */
  delay?: 'random' | number | undefined;
  /** If true, the page will be skipped if the visibleIf condition is not met */
  visibleIf?: string;
  category?: string;
  autoAdvance?: boolean;
  hideProgressBar?: boolean;
  hideCategory?: boolean;
  identifyPropertyName?: string;
};

type ImagePickerOptions = Omit<RiseQuestionImagePicker, 'type'> & {
  /** How long to show the spinner for before moving to the next page. `random` will show a random delay between 1 and 5 seconds. */
  delay?: 'random' | number | undefined;
  /** If true, the page will be skipped if the visibleIf condition is not met */
  visibleIf?: string;
  autoAdvance?: boolean;
  category?: string;
  hideProgressBar?: boolean;
  hideCategory?: boolean;
  identifyPropertyName?: string;
};

type CheckboxOptions = Omit<RiseQuestionCheckbox, 'type'> & {
  /** How long to show the spinner for before moving to the next page. `random` will show a random delay between 1 and 5 seconds. */
  delay?: 'random' | number | undefined;
  /** If true, the page will be skipped if the visibleIf condition is not met */
  visibleIf?: string;
  category?: string;
  hideProgressBar?: boolean;
  hideCategory?: boolean;
  identifyPropertyName?: string;
};

export const textPage = ({
  visibleIf,
  delay,
  category,
  hideCategory,
  hideProgressBar,
  ...textOptions
}: TextOptions): RiseSurveyPage => {
  return page(
    {
      type: 'text',
      ...textOptions,
    },
    {
      visibleIf,
      delayDuration: delay,
      category,
      hideProgressBar,
      hideCategory,
    },
  );
};

export const imagePickerPage = ({
  visibleIf,
  delay,
  category,
  hideCategory,
  hideProgressBar,
  autoAdvance,
  identifyPropertyName,
  ...imagePickerOptions
}: ImagePickerOptions): RiseSurveyPage => {
  return page(
    {
      type: 'imagepicker',
      ...imagePickerOptions,
    },
    {
      visibleIf,
      delayDuration: delay,
      category,
      autoAdvance,
      hideProgressBar,
      hideCategory,
      identifyPropertyName,
    },
  );
};

export const radioPage = ({
  visibleIf,
  delay,
  category,
  autoAdvance,
  identifyPropertyName,
  hideProgressBar,
  hideCategory,
  ...radioOptions
}: RadioOptions): RiseSurveyPage => {
  return page(
    {
      type: 'radiogroup',
      ...radioOptions,
    },
    {
      visibleIf,
      delayDuration: delay,
      category,
      autoAdvance,
      identifyPropertyName,
      hideProgressBar,
      hideCategory,
    },
  );
};

export const checkboxPage = ({
  visibleIf,
  delay,
  category,
  hideProgressBar,
  hideCategory,
  identifyPropertyName,
  ...checkboxOptions
}: CheckboxOptions): RiseSurveyPage => {
  return page(
    {
      type: 'checkbox',
      ...checkboxOptions,
    },
    {
      visibleIf,
      delayDuration: delay,
      category,
      hideProgressBar,
      hideCategory,
      identifyPropertyName,
    },
  );
};
