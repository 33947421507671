import styles from './CheckoutButtons.module.css';

export const BlackButton = ({title, onClick}: {title: string; onClick?: () => void}) => (
  <button className={[styles.blackButton, styles.button].join(' ')} onClick={() => (onClick ? onClick() : null)}>
    {title}
  </button>
);

export const PurpleButton = ({title, onClick}: {title: string; onClick?: () => void}) => (
  <button className={[styles.purpleButton, styles.button].join(' ')} onClick={() => (onClick ? onClick() : null)}>
    {title}
  </button>
);

export const DiscountButton = ({title, onClick}: {title: string; onClick?: () => void}) => (
  <button className={[styles.discountButton, styles.button].join(' ')} onClick={() => (onClick ? onClick() : null)}>
    {title}
  </button>
);

export const ClearButton = ({
  title,
  onClick,
  style,
}: {
  title: string;
  onClick?: () => void;
  style?: React.CSSProperties;
}) => (
  <button
    className={[styles.clearButton, styles.button].join(' ')}
    onClick={() => (onClick ? onClick() : null)}
    style={style}
  >
    {title}
  </button>
);
