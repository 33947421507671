import styles from './ISIScale.module.css';
import pinAtRisk from './assets/pin_at_risk.svg';
import pinPoor from './assets/pin_poor.svg';
import pinCritical from './assets/pin_critical.svg';
import pinHealthy from './assets/pin_healthy.svg';
import {useEffect, useState} from 'react';

const END_OFF_SET = '20px';
const SCALE_MAX = 28;

const Pin = ({value}: {value: number}) => {
  const [currentImage, setCurrentImage] = useState(pinHealthy);

  const getPinImage = (score: number) => {
    if (score >= 0 && score <= 7) return pinHealthy;
    if (score >= 8 && score <= 14) return pinAtRisk;
    if (score >= 15 && score <= 21) return pinPoor;
    if (score >= 22 && score <= 28) return pinCritical;
    return pinHealthy; // Default fallback
  };

  useEffect(() => {
    setCurrentImage(getPinImage(value));
  }, [value]);

  return (
    <div
      className={styles.pin}
      style={{
        marginLeft: '-13.5px',
        left: `${Math.min(Math.max(value / SCALE_MAX, 0), 1) * 100}%`,
      }}
    >
      <img
        src={currentImage}
        alt="pin"
        style={{
          transition: 'opacity 0.2s ease-in-out',
        }}
      />
      <div className={styles.pinLabel}>{value >= 0 ? value : ''}</div>
    </div>
  );
};

export const ISIScale: React.FC<{value: number}> = ({value}) => {
  const [currentValue, setCurrentValue] = useState(-1);
  useEffect(() => {
    setTimeout(() => {
      setCurrentValue(value);
    }, 250);
  }, [value]);
  return (
    <div className={styles.container}>
      <div className={styles.scale}>
        <div className={styles.scaleEndLabel} style={{width: END_OFF_SET, textAlign: 'right', color: '#491CFF'}}>
          0
        </div>
        <div className={styles.bar}>
          <Pin value={currentValue} />
          <div className={[styles.segment, styles.segment1].join(' ')}>
            <div className={styles.scaleLabel}>Healthy</div>
          </div>
          <div className={[styles.segment, styles.segment2].join(' ')}>
            <div className={styles.scaleLabel}>At risk</div>
          </div>
          <div className={[styles.segment, styles.segment3].join(' ')}>
            <div className={styles.scaleLabel}>Poor</div>
          </div>
          <div className={[styles.segment, styles.segment4].join(' ')}>
            <div className={styles.scaleLabel}>Critical</div>
          </div>
        </div>
        <div className={styles.scaleEndLabel} style={{width: END_OFF_SET, textAlign: 'left', color: '#E52242'}}>
          {SCALE_MAX}
        </div>
      </div>
    </div>
  );
};
