import React, {useState} from 'react';
import {currencySymbolAtStart} from 'utils/locale';
import {PlanInfo} from 'utils/stripePlans';
import styles from './PlanOptions.module.css';
export interface PlanItem {
  id: string;
  title: string;
  subtitle?: string;
  plan: PlanInfo;
  description: string | React.ReactNode;
  badgeText?: string;
}

interface ItemProps {
  item: PlanItem;
  isOpen: boolean;
  onClick: () => void;
}

const Item: React.FC<ItemProps> = ({item, isOpen, onClick}) => {
  const plan = item.plan;

  const splitPrice = plan.perDay.toFixed(2).toString().split('.');
  const atStart = currencySymbolAtStart(plan.introPrice, plan);
  const currencySymbol = plan.label;

  return (
    <div className={`${styles.item} ${isOpen ? styles.open : ''}`} onClick={onClick}>
      {item.badgeText ? <div className={styles.badge}>{item.badgeText}</div> : null}
      <div className={styles.header}>
        <div className={styles.titleContainer}>
          {item.subtitle ? <div className={styles.subtitle}>{item.subtitle}</div> : null}
          <div className={styles.title}>{item.title}</div>
        </div>

        <div className={styles.perDayContainer}>
          {atStart ? <div className={styles.currency}>{currencySymbol}</div> : null}
          <div className={styles.dollars}>{splitPrice[0]}</div>
          <div>
            <div className={styles.cents}>
              {splitPrice[1]}
              {!atStart ? ` ${currencySymbol}` : null}
            </div>
            <div className={styles.perDayLabel}>/day</div>
          </div>
        </div>
      </div>
      <div className={styles.content}>
        <div className={styles.contentInner}>
          <div className={styles.description}>{item.description}</div>
        </div>
      </div>
    </div>
  );
};

interface PlanOptionsProps {
  items: PlanItem[];
  defaultSelection?: string;
  onSelectionChange?: (item: PlanItem, index: number) => void;
}

export const PlanOptions: React.FC<PlanOptionsProps> = ({
  items,
  defaultSelection: defaultOpenId,
  onSelectionChange,
}) => {
  const [openItemId, setOpenItemId] = useState<string>(defaultOpenId ?? items[0]?.id);

  const handleItemClick = (item: PlanItem, index: number) => {
    setOpenItemId(item.id);
    onSelectionChange?.(item, index);
  };

  return (
    <div className={styles.container}>
      {items.map((item, index) => (
        <Item key={item.id} item={item} isOpen={item.id === openItemId} onClick={() => handleItemClick(item, index)} />
      ))}
    </div>
  );
};
