import Environment from 'utils/Environment';
import {SupportedCountryCode} from './stripePlans';

const inProduction = Environment.isProduction;

// allow_list_of_price_ids = [
//   # test eBooks
//   "price_1Qw6neH7jib4x1GyWyXguKOX", <AUD 23.99>
//   "price_1Qw642H7jib4x1GyHPkVoaOi", <USD 19.99>
//   "price_1Qw6neH7jib4x1Gyl3dFWtB1", <CAD 21.99>
//   "price_1Qw6neH7jib4x1Gy89WrKErs", <GBP 11.99>
//   "price_1Qw6neH7jib4x1GyvKwqf7pF", <EUR 14.99>
//   # prod eBooks
//   "price_1Qw7MkH7jib4x1GyTf2CkJuE", <AUD 23.99>
//   "price_1Qw7MkH7jib4x1GyvbhJVgF6", <USD 19.99>
//   "price_1Qw7MkH7jib4x1Gy9gedAy7H", <CAD 21.99>
//   "price_1Qw7MkH7jib4x1GyD6Ne3n4x", <GBP 11.99>
//   "price_1Qw7MkH7jib4x1Gy4tHh7Drf", <EUR 14.99>
// ]

export type UpsellProductInfo = {
  price: number;
  strikeThroughPrice: number;
  discountPercent: number;
  id: string;
  currency: string;
  productType: string;
  title: string;
  description: string;
  label: string;
};

const getDiscountPercent = (price: number, strikeThroughPrice: number) => {
  return Math.round(((strikeThroughPrice - price) / strikeThroughPrice) * 100);
};

export const eBookProduct: Record<SupportedCountryCode, UpsellProductInfo> = {
  AU: {
    price: 18.99,
    strikeThroughPrice: 24.99,
    discountPercent: getDiscountPercent(18.99, 24.99),
    id: inProduction ? 'price_1Qw7MkH7jib4x1GyTf2CkJuE' : 'price_1Qw6neH7jib4x1GyWyXguKOX',
    currency: 'aud',
    productType: 'eBook',
    title: 'The RISE Guide to Improving Your Sleep & Energy',
    description: 'A comprehensive, science-backed eBook',
    label: 'A$',
  },
  US: {
    price: 14.99,
    strikeThroughPrice: 19.99,
    discountPercent: getDiscountPercent(14.99, 19.99),
    id: inProduction ? 'price_1Qw7MkH7jib4x1GyvbhJVgF6' : 'price_1Qw642H7jib4x1GyHPkVoaOi',
    currency: 'usd',
    productType: 'eBook',
    title: 'The RISE Guide to Improving Your Sleep & Energy',
    description: 'A comprehensive, science-backed eBook',
    label: '$',
  },
  CA: {
    price: 16.99,
    strikeThroughPrice: 21.99,
    discountPercent: getDiscountPercent(16.99, 21.99),
    id: inProduction ? 'price_1Qw7MkH7jib4x1Gy9gedAy7H' : 'price_1Qw6neH7jib4x1Gyl3dFWtB1',
    currency: 'cad',
    productType: 'eBook',
    title: 'The RISE Guide to Improving Your Sleep & Energy',
    description: 'A comprehensive, science-backed eBook',
    label: 'C$',
  },
  GB: {
    price: 9.99,
    strikeThroughPrice: 14.99,
    discountPercent: getDiscountPercent(9.99, 14.99),
    id: inProduction ? 'price_1Qw7MkH7jib4x1GyD6Ne3n4x' : 'price_1Qw6neH7jib4x1Gy89WrKErs',
    currency: 'gbp',
    productType: 'eBook',
    title: 'The RISE Guide to Improving Your Sleep & Energy',
    description: 'A comprehensive, science-backed eBook',
    label: '£',
  },
  EU: {
    price: 9.99,
    strikeThroughPrice: 14.99,
    discountPercent: getDiscountPercent(9.99, 14.99),
    id: inProduction ? 'price_1Qw7MkH7jib4x1Gy4tHh7Drf' : 'price_1Qw6neH7jib4x1GyvKwqf7pF',
    currency: 'eur',
    productType: 'eBook',
    title: 'The RISE Guide to Improving Your Sleep & Energy',
    description: 'A comprehensive, science-backed eBook',
    label: '€',
  },
};
