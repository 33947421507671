import React from 'react';
import {RiseInterstitialProps} from 'screens/RiseSurvey/components/RiseInterstitialTemplate/RiseInterstitialBase';
import RiseSurveyInterstitialControls from 'screens/RiseSurvey/components/RiseSurveyControls/RiseSurveyInterstitialControls';
import planGraphic from './assets/plan_graphic.svg';
import styles from './ISISnapshotInterstitial.module.css';

const SleepSnapshotInterstitialPlan: React.FC<RiseInterstitialProps> = () => {
  return (
    <div className={`${styles.container} ${styles.fadeIn}`}>
      <div className={styles.title}>RISE can help</div>
      <div className={styles.description}>Your plan focuses on these areas</div>
      <img src={planGraphic} className={styles.planGraphic} />
      <RiseSurveyInterstitialControls continueButtonTitle="Next" />
    </div>
  );
};

export default SleepSnapshotInterstitialPlan;
